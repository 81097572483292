// Los iconos se suben a BUNNYCDN y se pueden descargar de FLATICON.

function IconosWidgetsComponenteDev1(text) {
  var imagen = "";
  switch (text) {
    case "pdf":
      imagen = "https://octopusmultinivel.b-cdn.net/octopus10/pdf.svg";
      break;
    case "indicadores":
      imagen = "https://pulosfDevelopers.b-cdn.net/iconosWidgets/economy.png";
      break;
    case "tranferNumero":
      imagen =
        "https://pulosfDevelopers.b-cdn.net/iconosWidgets/number-blocks.png";
      break;
    default:
      imagen =
        "https://octopusmultinivel.b-cdn.net/octopus10/question-mark.svg";
      break;
  }
  return imagen;
}

function ListadoWidgetsBloques(props) {
  return (
    <>
      <div
        onClick={() => {
          props.showDrawer("pdf");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="pdf"
          src="https://octopusmultinivel.b-cdn.net/octopus10/pdf.svg"
        />
        <span>Pdf</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("indicadores");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="indicadores"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/economy.png"
        />
        <span>Indicadores</span>
      </div>
      <div
        onClick={() => {
          props.showDrawer("tranferNumero");
        }}
        className="item-lista-widgets"
      >
        <img
          width="25px"
          style={{ marginRight: 15 }}
          alt="tranferNumero"
          src="https://pulosfDevelopers.b-cdn.net/iconosWidgets/number-blocks.png"
        />
        <span>Numero</span>
      </div>
    </>
  );
}

export { IconosWidgetsComponenteDev1, ListadoWidgetsBloques };
