import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { propsToClassKey } from "@material-ui/styles";
import { Drawer, Space, Table } from "antd";
import moment from "moment-timezone";
import React from "react";
import Moment from "react-moment";
import { Api } from "../../../../../../api/configApi";
import { store } from "../../../../../../store";
import FormEditarAtributos from "./FormEditarAtributos";
import "moment/locale/es";
moment.locale("es");

function ListaAtributosPerfilAfiliado(props) {
  const [atributos, setAtributos] = React.useState([]);
  const [drawerEditarAfiliado, setDrawerEditarAfiliado] = React.useState(false);
  const [atributoActual, setAtributoActual] = React.useState(null);

  const columns = [
    {
      title: "Atributo",
      dataIndex: "atributo",
      key: "atributo",
    },
    {
      title: "Valor",
      dataIndex: "valor",
      key: "valor",
      render: (text, record) => {
        return store.losatributos.dates.some(
          (e) => e.nombre === record.atributo
        ) && record.valor ? (
          <Moment format="DD [de] MMMM [del] YYYY, HH:mm" date={record.valor} />
        ) : (
          <span>{record.valor}</span>
        );
      },
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (e, not) => {
        return (
          <FontAwesomeIcon
            onClick={() => {
              setAtributoActual(not);
              setDrawerEditarAfiliado(true);
            }}
            style={{ cursor: "pointer" }}
            icon={faEdit}
          />
        );
      },
    },
  ];

  function getAtris() {
    const atri = [
      ...store.losatributos.texts.reduce((acc, cur) => {
        acc.push(cur.nombre);
        return acc;
      }, []),
      ...store.losatributos.numbers.reduce((acc, cur) => {
        acc.push(cur.nombre);
        return acc;
      }, []),
      ...store.losatributos.dates.reduce((acc, cur) => {
        acc.push(cur.nombre);
        return acc;
      }, []),
    ];

    Api("afiliados", "GetAtributosAll", {
      atributos: atri,
      idAfiliado: props.infoAfiliado._id,
    }).then((res) => {
      const atriFormateados = [];

      atri.forEach((e) => {
        atriFormateados.push({
          valor: res[e],
          atributo: e,
        });
      });
      setAtributos(atriFormateados);
    });
  }

  React.useEffect(() => {
    setAtributos([]);
    getAtris();
  }, [props.infoAfiliado]);
  return (
    <>
      <Drawer
        visible={drawerEditarAfiliado}
        title="Editar Atributo"
        onClose={() => {
          setDrawerEditarAfiliado(false);
        }}
      >
        {atributoActual && (
          <FormEditarAtributos
            atributo={atributoActual}
            afiliado={props.infoAfiliado}
            setDrawerEditarAfiliado={setDrawerEditarAfiliado}
            getAtris={getAtris}
          />
        )}
      </Drawer>
      <Table columns={columns} dataSource={atributos} />
    </>
  );
}

export default ListaAtributosPerfilAfiliado;
