function AccionesDisponibles() {
  return [
    {
      _id: "6266de4e9a66af4426e209cf",
      titulo: "Madurar Inversiones",
      descripcion:
        "Actualizar rendimiento por inversiones realizadas en diferentes paquetes comprados",
      tags: ["Listados"],
      campos: [
        {
          tipo: "listados",
          name: "listadoInversiones",
          requerido: true,
          label: "Listado inversiones",
          descripcion:
            "Listado que contiene los campos con el monto invertido y las utilidades",
        },
        {
          tipo: "campos",
          dependencia: "listadoInversiones",
          tipoCampo: "numero",
          requerido: true,
          label: "Campo de la inversión",
          name: "campoInversion",
          descripcion: "campo donde esta el monto invertido",
        },
        {
          tipo: "select",
          label: "¿Cuales items?",
          requerido: true,
          name: "cualesItems",
          opciones: [
            "todos los items",
            "último item agregado",
            "primer item agregado",
          ],
        },
        {
          tipo: "condicionalCampoMultiple",
          dependencia: "listadoInversiones",
          requerido: true,
          name: "condicionalItem",
          label: "Filtrado por",
        },
        {
          tipo: "campos",
          dependencia: "listadoInversiones",
          tipoCampo: "numero",
          requerido: true,
          name: "campoUtilidades",
          label: "Campo utilidades",
          descripcion: "Campo donde esta el monto de las utilidades",
        },
        {
          tipo: "numero",
          name: "porcentajeApagar",
          label: "Porcentaje a pagar",
          requerido: true,
          descripcion: "Porcentaje que se usara para realizar el calculo",
        },
        {
          tipo: "numero",
          name: "rendimientoMaximo",
          requerido: true,
          label: "Porcentaje máximo a pagar",
          descripcion: "Monto máximo a dar en rendimiento",
        },
        {
          tipo: "seleccionarOpcionCampo",
          dependencia: "listadoInversiones",
          name: "campoAlTerminar",
          label: "Actualizar campo (Al completar)",
          descripcion: "Actualizar el campo al pagar el máximo permitido",
          requerido: true,
        },
        {
          tipo: "listados",
          name: "listadoHistorial",
          label: "Listado Historial",
          descripcion: "Listado a donde se agregar un nuevo item",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          tipoCampo: "numero",
          name: "campoItemHistorial",
          label: "campo para el monto agregado",
          descripcion: "campo donde se sumará la ganancia",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          tipoCampo: "numero",
          name: "campoItemInversion",
          label: "Campo para la inversión del paquete",
          descripcion: "Campo donde va le inversión del paquete",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          tipoCampo: "texto",
          label: "Campo del para el ID de la inversion",
          descripcion: "Campo donde va el ID de la inversión del campo",
          name: "campoItemId",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          name: "totalRendimientoAntes",
          label: "Campo para el total rendimiento antes",
          descripcion:
            "Campo donde va el total del rendimiento recibido hasta antes de esta operación",
          tipoCampo: "numero",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          name: "totalRendimientoActual",
          label: "Campo para el rendimiento total actual",
          descripcion:
            "Campo donde va el total del rendimiento recibido hasta el momento",
          tipoCampo: "numero",
        },
      ],
      f: "madurarInversiones",
      form: "unico",
    },
    {
      _id: "626945f90c4831ec23242f3e",
      titulo: "Madurar Inversiones (Aceleración)",
      descripcion: "Acelera un paquete de inversión con un monto especifico",
      tags: ["Listados"],
      campos: [
        {
          tipo: "texto",
          name: "_idAccion",
          requerido: true,
          label: "ID acción",
          descripcion: "coloca un id para esta acción",
        },
        {
          tipo: "listados",
          name: "listadoInversiones",
          requerido: true,
          label: "Listado inversiones",
          descripcion:
            "Listado que contiene los campos con el monto invertido y las utilidades",
        },
        {
          tipo: "campos",
          dependencia: "listadoInversiones",
          tipoCampo: "numero",
          requerido: true,
          label: "Campo de la inversión",
          name: "campoInversion",
          descripcion: "campo donde esta el monto invertido",
        },
        {
          tipo: "select",
          label: "¿Cuales items?",
          requerido: true,
          name: "cualesItems",
          opciones: [
            "todos los items",
            "último item agregado",
            "primer item agregado",
          ],
        },
        {
          tipo: "condicionalCampoMultiple",
          dependencia: "listadoInversiones",
          requerido: true,
          name: "condicionalItem",
          label: "Filtrado por",
        },
        {
          tipo: "campos",
          dependencia: "listadoInversiones",
          tipoCampo: "numero",
          requerido: true,
          name: "campoUtilidades",
          label: "Campo utilidades",
          descripcion: "Campo donde esta el monto de las utilidades",
        },
        {
          tipo: "variableAtributo",
          name: "montoAsumar",
          label: "Monto a sumar",
          requerido: true,
          descripcion:
            "Monto que la inversión recibirá como abono al rendimiento",
        },
        {
          tipo: "numero",
          name: "rendimientoMaximo",
          requerido: true,
          label: "Porcentaje máximo a pagar",
          descripcion: "Monto máximo a dar en rendimiento",
        },
        {
          tipo: "seleccionarOpcionCampo",
          dependencia: "listadoInversiones",
          name: "campoAlTerminar",
          label: "Actualizar campo (Al completar)",
          descripcion: "Actualizar el campo al pagar el máximo permitido",
          requerido: true,
        },
        {
          tipo: "listados",
          name: "listadoHistorial",
          label: "Listado Historial",
          descripcion: "Listado a donde se agregar un nuevo item",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          tipoCampo: "numero",
          name: "campoItemHistorial",
          label: "campo para el monto agregado",
          descripcion: "campo donde se sumará la ganancia",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          tipoCampo: "numero",
          name: "campoItemInversion",
          label: "Campo para la inversión del paquete",
          descripcion: "Campo para la inversión del paquete",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          tipoCampo: "texto",
          label: "Campo del para el ID de la inversion",
          descripcion: "Campo donde va el ID de la inversión del campo",
          name: "campoItemId",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          name: "totalRendimientoAntes",
          label: "Campo para el total rendimiento antes",
          descripcion:
            "Campo donde va el total del rendimiento recibido hasta antes de esta operación",
          tipoCampo: "numero",
        },
        {
          tipo: "campos",
          dependencia: "listadoHistorial",
          name: "totalRendimientoActual",
          label: "Campo para el rendimiento total actual",
          descripcion:
            "Campo donde va el total del rendimiento recibido hasta el momento",
          tipoCampo: "numero",
        },
      ],
      f: "madurarInversionesAceleracion",
      form: "multiple",
    },
    {
      _id: "626bfbf82ccd3a07b6ed3b55",
      titulo: "Actualizar campo multiple",
      descripcion:
        "Actualizar todos los items condicionados por un campo multiple",
      tags: ["Listados"],
      campos: [
        {
          tipo: "listados",
          name: "listado",
          requerido: true,
          label: "Listado",
          descripcion: "Listado a actualizar",
        },
        {
          tipo: "seleccionarOpcionCampo",
          label: "Condicional",
          dependencia: "listado",
          requerido: true,
          name: "condicional",
        },
        {
          tipo: "seleccionarOpcionCampo",
          label: "Nuevo Valor",
          dependencia: "listado",
          requerido: true,
          name: "nuevoValor",
        },
      ],
      f: "actualizarCampoMultiple",
      form: "unico",
    },
    {
      _id: "6272bb0fb716058b384af194",
      titulo: "Actualizar campo multiple (Item Actual)",
      descripcion: "Actualizar un campo multiple del item actual",
      tags: ["Listados"],
      campos: [
        {
          tipo: "listados",
          name: "listado",
          requerido: true,
          label: "Listado",
          descripcion: "Listado a actualizar",
        },
        {
          tipo: "condicionalCampoMultiple",
          label: "Condicional",
          dependencia: "listado",
          requerido: true,
          name: "condicional",
        },
        {
          tipo: "seleccionarOpcionCampo",
          label: "Nuevo Valor",
          dependencia: "listado",
          requerido: true,
          name: "nuevoValor",
        },
      ],
      f: "actualizarCampoMultipleItemActual",
      form: "unico",
    },
    {
      _id: "18545785d548sd88rrr7t7t",
      titulo: "Dispersión de pagos con coinpayments",
      descripcion: "Dispersión de pagos (atributos tipo número)",
      tags: ["atributo número"],
      f: "dispersionCoinpayments",
      form: "unico",
      campos: [
        {
          tipo: "atributosNumber",
          name: "amountIndicator",
          requerido: true,
          label: "Atributo tipo número",
          descripcion: "Dispersion de atributo tipo número",
        },
        {
          tipo: "numero",
          name: "commissionPorcent",
          label: "Porcentaje de comisión - FI",
          requerido: true,
          descripcion:
            "Porcentaje que se descontará del total de atributo número al realizar la dispersión",
        },
        {
          tipo: "select",
          label: "Seleccione el código de su wallet",
          requerido: true,
          name: "nombreWallet",
          opciones: [
            "Binance Coin - BNB",
            "Bitcoin - BTC",
            "Bitcoin cash - BCH",
            "Cardano - ADA",
            "Chainlink - LINK",
            "Dogecoin - DOGE",
            "Ethereum, Ether - ETH",
            "Litecoin - LTC",
            "Litecoin Testnet - LTCT",
            "Polkadot - DOT",
            "Ripple - XRP",
            "Stellar - XLM",
            "Tether - USDT",
            "Tezos - XTZ",
            "Uniswap - UNI",
            "USD Coin - USDC",
          ],
        },
        {
          tipo: "camposPerfilAfiliado",
          name: "campoNombreWallet",
          label: "Selecciona el campo de la wallet",
          requerido: true,
          descripcion:
            "Campo donde se almacena el HASH para la dispersión de pagos",
        },
        {
          tipo: "select",
          label: "Activar confirmación de dispersión de pagos por email",
          requerido: true,
          name: "confirmacionEmail",
          opciones: [
            "Si",
            "No",
          ],
        }
      ],
    },
  ];
}

export default AccionesDisponibles;
