import React from "react";
import { Form, Input, Select, Button, InputNumber, Modal, Radio } from "antd";
import { Api } from "../../../../../api/configApi";

const { Option } = Select;

const CoinPayments = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const refInputNombreListado = React.useRef();
  const refListaCriptos = React.useRef();
  const [listaCriptos, setListaCriptos] = React.useState();
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);
  const [valorCheck, setValorCheck] = React.useState();
  const [valorCheckP, setValorCheckP] = React.useState();
  const [mult, setMult] = React.useState(false);

  const enviarNombreListado = () => {
    Api(
      "pasarelas",
      props.formu.getFieldsValue().listaIntegraciones,
      { type: "InsertListado" },
      { nombreListado: refInputNombreListado.current },
      refRuta.current
    )
      .then((res) => {
        setListaCriptos(res.listados);
      })
      .catch((err) => console.log(err));
  };

  const consultarListadoCriptos = () => {
    Api(
      "pasarelas",
      props.formu.getFieldsValue().listaIntegraciones,
      { type: "enviarListadostypeCrypto" },
      {},
      refRuta.current
    )
      .then((res) => {
        refListaCriptos.current = res.listados;
        setListaCriptos(refListaCriptos.current);
      })
      .catch((err) => console.log(err));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (refInputNombreListado.current) {
      enviarNombreListado();
    }
    props.formu.setFieldsValue({ nombreListado: "" });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    props.formu.getFieldsValue().usarMultiplos
      ? setValorCheckP(props.formu.getFieldsValue().usarMultiplos)
      : setValorCheckP("false");
    if (props.formu.getFieldsValue().montoFijoRango) {
      setValorCheck(props.formu.getFieldsValue().montoFijoRango);
      if (props.formu.getFieldsValue().montoFijoRango == "montoFijoTrue") {
        setMult(true);
      } else {
        setMult(false);
      }
    } else {
      setValorCheck("ninguno");
      setMult(false);
    }

    consultarListadoCriptos();
  }, [props]);

  return (
    <>
      <Form.Item
        name="listaCriptos"
        label="Monedas permitidas"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Por favor escoge las monedas permitidas"
          style={{ width: "100%" }}
        >
          {props.listaMonedasArray.map((moneda, index) => {
            if (moneda.isFiat !== 1) {
              return (
                <Option
                  key={index}
                  value={moneda.codigoMoneda + "-" + moneda.nombreMoneda}
                >
                  {moneda.nombreMoneda}
                </Option>
              );
            }
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="monedaEscogidaMostrar"
        label="Escoge la moneda que quieres mostrar en el valor"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Select
          showSearch
          placeholder="Por favor escoge una moneda"
          style={{ width: "100%" }}
        >
          {props.listaMonedasArray.map((moneda, index) => {
            return (
              <Option
                key={index}
                value={moneda.codigoMoneda + "-" + moneda.nombreMoneda}
              >
                {moneda.nombreMoneda}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="montoFijoRango"
        label="Escoge una de las siguientes opciones"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Radio.Group
          options={[
            { label: "Permitir monto fijo", value: "montoFijoTrue" },
            { label: "Permitir rango", value: "rangoTrue" },
            { label: "Ninguno", value: "ninguno" },
          ]}
          onChange={(e) => {
            setValorCheck(e.target.value);
            if (e.target.value == "montoFijoTrue") {
              setMult(true);
            } else {
              setMult(false);
            }
          }}
          value={valorCheck}
          optionType="button"
        />
      </Form.Item>

      {valorCheck == "montoFijoTrue" && (
        <div style={{ display: "flex", alignItems: "start" }}>
          <Form.Item
            name="montoPagar"
            label="Cantidad a ser pagada"
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              style={{ float: "left", width: "100%" }}
            ></InputNumber>
          </Form.Item>
        </div>
      )}

      {valorCheck == "rangoTrue" && (
        <div style={{ display: "flex", alignItems: "start" }}>
          <Form.Item
            name="montoMinimo"
            label="Monto mínimo a pagar"
            style={{ marginRight: "10px" }}
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              style={{ float: "left", width: "100%" }}
            ></InputNumber>
          </Form.Item>
          <Form.Item
            name="montoMaximo"
            label="Monto máximo a pagar"
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              style={{ float: "left", width: "100%" }}
            ></InputNumber>
          </Form.Item>
        </div>
      )}

      <Form.Item
        name="usarMultiplos"
        label="¿Deseas usar múltiplos en la cantidad a recibir?"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Radio.Group
          options={[
            { label: "Si", value: "true", disabled: mult },
            { label: "No", value: "false" },
          ]}
          onChange={(e) => {
            setValorCheckP(e.target.value);
          }}
          value={valorCheckP}
          optionType="button"
        />
      </Form.Item>
      {valorCheckP == "true" && !mult && (
        <div style={{ display: "flex", alignItems: "start" }}>
          <Form.Item
            name="multiplo"
            label="La cantidad ingresada debe ser múltiplo de:"
            rules={[
              {
                required: true,
                message: "Atributo obligatorio",
              },
            ]}
          >
            <InputNumber
              defaultValue={0}
              style={{ float: "left", width: "100%" }}
            ></InputNumber>
          </Form.Item>
        </div>
      )}

      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Button onClick={showModal}>Crear listado</Button>
      </div>

      <Modal
        title="Crear un listado para almacenar el pago"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form.Item name="nombreListado" label="Nombre del listado">
          <Input
            onChange={(e) => {
              refInputNombreListado.current = e.target.value;
            }}
            placeholder="Ingresa el nombre del listado"
          ></Input>
        </Form.Item>
      </Modal>
      <Form.Item
        name="listaListados"
        label="Listados creados"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Select
          placeholder="Por favor escoge un listado para almacenar el pago"
          style={{ width: "100%" }}
        >
          {listaCriptos &&
            listaCriptos.map((currency, index) => {
              return (
                <Option key={index} value={currency._id}>
                  {currency.nombre}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
    </>
  );
};

export default CoinPayments;
