import React, { memo } from "react";

import { Drawer, Table, Image, Switch, Input, Card, Modal } from "antd";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/es";
import jwtDecode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../../api/configApi";
import { store } from "../../../../../store";
import EditarPerfilAfiliado from "../../../../../components/widgets/tipos/tabla/perfilAfiliado/EditarPerfilAfiliado";

const { Search } = Input;
function DesignWidgetTabla(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState("");
  const [infoElemento, setInfoElemento] = React.useState({});
  const [cargando, setCargando] = React.useState(true);
  const [columnas, setColumnas] = React.useState([]);
  const [datos, setDatos] = React.useState([]);
  const [drawerPerfil, setDrawerPerfil] = React.useState(false);
  const [ideAfiliado, setIdeAfiliado] = React.useState("");
  const idAfiliadoActual = React.useRef("");
  const losDatos = React.useRef([]);
  const [visible, setVisible] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);
  const refListas = React.useRef([]);
  const refListaPrueba = React.useRef([]);
  const [indiceClick, setIndiceClick] = React.useState();
  const [resolucion, setResolucion] = React.useState();
  const refResolucion = React.useRef();

  React.useEffect(() => {
    if (drawerPerfil && idAfiliadoActual.current !== "") {
      Api("afiliados", "GetIde", { idAfiliado: idAfiliadoActual.current }).then(
        (res) => {
          setIdeAfiliado(res.ide);
        }
      );
    }
  }, [drawerPerfil]);

  function Buscar(Elwidget) {
    setCargando(true);
    let atrr = {};
    [
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
      ...store.losatributos.dates,
    ].forEach((e) => {
      atrr["atributo_" + e._id] = e.nombre;
    });

    Api("widgets", "tabla", {
      idWidget: Elwidget._id,
      atributos: atrr,
    })
      .then((res) => {
        setCargando(false);
        if (res.datos.data.length > 0) {
          res.datos.data.forEach((e) => {
            refListas.current = [];

            Object.keys(e).forEach((key, index) => {
              refListas.current.push({
                propiedad: key,
                valor: e[key],
              });
            });

            refListaPrueba.current.push(refListas.current);

            if (e?.fecha) {
              e.fecha = (
                <Moment locale="es" local format="D MMM YYYY">
                  {e.fecha}
                </Moment>
              );
            }
          });
          setDatos(res.datos.data);
          losDatos.current = res.datos.data;
        }
      })
      .catch((er) => {
        setCargando(false);
        console.log(er);
      });
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    if (widgetActual.listar === "listado") {
      var listado = store.listados.find((list) => {
        return list._id === widgetActual.listado;
      });
    }

    setWidget(widgetActual);
    var columnasNuevas = [];
    widgetActual.columnas.forEach((element) => {
      columnasNuevas.push({
        title: () => {
          if (element.split("_")[0] === "atributo") {
            return [
              ...store.losatributos.numbers,
              ...store.losatributos.texts,
              ...store.losatributos.dates,
            ].find((e) => e._id === element.split("_")[1]).nombre;
          } else {
            return element[0].toUpperCase() + element.slice(1);
          }
        },
        dataIndex: element,
        key: element,
        render: (e, not, as) => {
          return widgetActual.listar === "listado" ? (
            listado.campos.find((a) => a.NombreCampo == element)?.tipoCampo ===
            "url" ? (
              e ? (
                <a href={e} target="_blank" rel="noreferrer">
                  Link
                </a>
              ) : null
            ) : listado.campos.find((a) => a.NombreCampo == element)
                ?.tipoCampo === "imagen" ? (
              <Image
                style={{ display: e ? "" : "none", width: 50 }}
                src={e}
                alt="imagen"
              />
            ) : listado.campos.find((a) => a.NombreCampo == element)
                ?.tipoCampo === "switch" ? (
              <Switch disabled value={e} />
            ) : (
              <span>{e}</span>
            )
          ) : (
            <span>{e}</span>
          );
        },
      });
    });
    const token = jwtDecode(localStorage.getItem("token"));
    if (token.admin && widgetActual.tipoAfiliado) {
      columnasNuevas.push({
        title: "",
        dataIndex: "Acciones",
        key: "Acciones",
        render: (text, record) => (
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={faEye}
            onClick={() => {
              idAfiliadoActual.current = record._id;
              setDrawerPerfil(true);
              setInfoElemento(record);
            }}
          />
        ),
      });
    }

    setColumnas(columnasNuevas);
    Buscar(widgetActual);

    return () => {
      setWidget(null);
      setContenido("");
      setColumnas([]);
      setDatos([]);
      setDrawerPerfil(false);
      setInfoElemento({});
      columnasNuevas = [];
    };
  }, [props]);

  React.useEffect(() => {
    refResolucion.current = window.innerWidth;
    setResolucion(refResolucion.current);
  }, []);

  function onSearch(valor) {
    if (valor) {
      const widgetActual = store.widgets.find((wid) => {
        return wid._id === props._id;
      });
      const Lascolumnas = widgetActual.columnas;
      const datosFiltrados = losDatos.current.filter((e) => {
        var encontrado = false;
        Lascolumnas.forEach((col) => {
          if (
            e[col] &&
            e[col].toString().toLowerCase().includes(valor.toLowerCase())
          ) {
            encontrado = true;
          }
        });
        return encontrado;
      });
      setDatos(datosFiltrados);
    } else {
      setDatos(losDatos.current);
    }
  }

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const showModal2 = () => {
    setVisible2(true);
  };

  const handleOk2 = (e) => {
    setVisible2(false);
  };

  const handleCancel2 = (e) => {
    setVisible2(false);
  };

  function vistaDatos(index, element) {
    return (
      <>
        <div key={index} style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ marginBottom: "0" }}>{element.valor}</p>
          <p style={{ fontWeight: 500 }}>{element.propiedad.toUpperCase()}</p>
        </div>
      </>
    );
  }

  return (
    <>
      {resolucion > 750 && (
        <div style={{ padding: 20 }}>
          <Drawer
            width={800}
            visible={drawerPerfil}
            title={`Perfil ide ${ideAfiliado}`}
            onClose={() => {
              setDrawerPerfil(false);
              Buscar(widget);
            }}
            destroyOnClose
          >
            <EditarPerfilAfiliado infoElemento={infoElemento} />
          </Drawer>
          <div style={estilos.contenido}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <strong style={{ marginLeft: 10, fontSize: 15 }}>
                {widget.titulo}
              </strong>
            </div>
            <Search
              placeholder="Buscar contenido"
              enterButton="Buscar"
              size="large"
              type="search"
              onSearch={onSearch}
              style={{ marginTop: 10, width: 300 }}
            />
            <Table
              loading={cargando}
              style={{ marginTop: 10 }}
              dataSource={datos}
              columns={columnas}
            />
          </div>
        </div>
      )}

      <Modal
        visible={visible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        bodyStyle={{
          overflowY: "scroll",
          height: "80vh",
          fontWeight: "300",
        }}
      >
        <Card title="Tabla de datos">
          {refListaPrueba.current.map((el, index) => {
            // if (index > 1) {
            //   return null;
            // }

            return (
              <>
                <Card
                  key={index}
                  type="inner"
                  style={{ marginBottom: "20px" }}
                  extra={
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIndiceClick(index);
                        showModal();
                      }}
                    >
                      Ver más
                    </span>
                  }
                >
                  {el.map((elem, index) => {
                    while (elem.propiedad != "_id") {
                      return <>{vistaDatos(index, elem)}</>;
                    }
                  })}
                </Card>
              </>
            );
          })}

          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          ></div>
        </Card>
      </Modal>

      {resolucion < 750 && (
        <div>
          <Card title="Tabla de datos">
            {refListaPrueba.current.map((el, index) => {
              if (index > 1) {
                return null;
              }

              return (
                <>
                  <Card
                    key={index}
                    type="inner"
                    style={{ marginBottom: "20px" }}
                    extra={
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIndiceClick(index);
                          showModal();
                        }}
                      >
                        Ver más
                      </span>
                    }
                  >
                    {el.map((elem, index) => {
                      if (index > 2) {
                        return null;
                      }

                      while (elem.propiedad != "_id") {
                        return <>{vistaDatos(index, elem)}</>;
                      }
                    })}
                  </Card>
                </>
              );
            })}

            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <p style={{ cursor: "pointer" }} onClick={showModal2}>
                Ver todos
              </p>
            </div>
          </Card>
        </div>
      )}

      {/* Modal dato individual */}
      <Modal
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ paddingTop: "50px", fontWeight: "300"}}
        zIndex={2000}
        closeIcon={<div style={{backgroundColor:"black", borderRadius:"50%", width:"30px", height:"30px", marginTop: "20px"}}><span style={{position:"absolute", top:"8px", left:"10px", fontWeight:"900", color:"white"}}>X</span></div>}

      >
        <div style={{ padding: "10px" }}>
          {refListaPrueba.current[indiceClick]?.map((elemento, index) => {
            while (elemento.propiedad != "_id") {
              return <>{vistaDatos(index, elemento)}</>;
            }
          })}
        </div>
      </Modal>
    </>
  );
}
const estilos = {
  contenido: {},
};
export default memo(DesignWidgetTabla);
