import { Select, message, Form, Progress } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import Submenu from "../../../components/submenu";
import { SketchPicker } from "react-color";
import { getAuth } from "firebase/auth";

import {
  setStoreActualizarColor,
  setStoreActualizarColorPrimario,
  setStoreColores,
  setStoreFuente,
  setStoreLogo,
  store,
} from "../../../store";
import ClickAwayListener from "react-click-away-listener";
import Boton from "../../../components/design/elementos/boton";
import { Api } from "../../../api/configApi";
import firebase from "firebase";
function PagesConfigDiseñoIdentidadvisual() {
  const [fuentes, setFuentes] = React.useState([
    "Poppins",
    "Roboto",
    "Roboto Condensed",
    "Roboto Mono",
    "Roboto Slab",
    "Montserrat",
    "Montserrat Alternates",
    "Montserrat Subrayada",
    "Open Sans",
    "Open Sans Condensed",
    "Nunito",
  ]);
  const [logo, setLogo] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [colorPaleta, setColorPaleta] = React.useState("#fff");
  const [paletabotones, setPaletabotones] = React.useState(false);
  const [paletaprimarios, setPaletaprimarios] = React.useState(false);
  const [paletaHeader, setPaletaHeader] = React.useState(false);
  const [paletaBarraLateral, setPaletaBarraLateral] = React.useState(false);
  const [paletasecundarios, setPaletasecundarios] = React.useState(false);
  const [paletatextos, setPaletatextos] = React.useState(false);
  const [primarioActivo, setPrimarioActivo] = React.useState(0);
  const [secundarioActivo, setSecundarioActivo] = React.useState(0);
  const [haciendocambios, setHaciendocambios] = React.useState(false);
  const [progresoSubidaLogo, setProgresoSubidaLogo] = React.useState(0);
  const [subiendoLogo, setSubiendoLogo] = React.useState(false);

  React.useEffect(() => {
    setLogo(store.logo);
  }, [store.logo]);

  function onChange(value) {
    setStoreFuente(value);
    setHaciendocambios(true);
  }

  const logoSeleccionado = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    const imagen = window.URL.createObjectURL(event.target.files[0]);
    setLogo(imagen);
  };

  const actualizarLogo = () => {
    try {
      setSubiendoLogo(true);
      // stora.put(selectedFile).then(function (snapshot) {
      //   console.log("Uploaded a blob or file!");
      // });
      var storageRef = firebase.storage().ref();
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          // console.log(idTokenResult);
          // console.log(idTokenResult.claims);
        })
        .catch((error) => {
          console.log(error);
        });
      var mountainsRef = storageRef.child(
        `/cuentas/${store.cuentaactiva}/${new Date().getMilliseconds()}${
          selectedFile.name
        }`
      );
      const uploadTask = mountainsRef.put(selectedFile);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgresoSubidaLogo(parseInt(progress));
        },
        function (error) {
          message.error("Algo no salio bien");
          console.log(error);
          setSubiendoLogo(false);
          setProgresoSubidaLogo(0);
          setSelectedFile(null);
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            Api(
              "cuentas",
              "UpdateLogo",
              { _id: store.cuentaactiva },
              { logo: downloadURL }
            ).then((res) => {
              if (res.success) {
                message.success("Logo actualizado");
                setStoreLogo(downloadURL);
                setSubiendoLogo(false);
                setProgresoSubidaLogo(0);
                setSelectedFile(null);
              } else {
                setSubiendoLogo(false);
                setProgresoSubidaLogo(0);
                setSelectedFile(null);
                message.error("Algo no salio bien");
              }
            });
          });
        }
      );

      const formData = new FormData();

      formData.append("File", selectedFile);
    } catch (error) {
      message.error("Algo no salio bien");
      setSubiendoLogo(false);
      setProgresoSubidaLogo(0);
      setSelectedFile(null);
    }
  };

  function cancelarCambios() {
    Api(
      "cuentas",
      "Get",
      { _id: store.cuentaactiva },
      { colores: 1, fuente: 1 }
    ).then((datocuenta) => {
      setStoreColores(datocuenta[0].colores);
      setStoreFuente(datocuenta[0].fuente);
    });

    setHaciendocambios(false);
  }

  function onBlur() {}

  function onFocus() {}

  function onSearch(val) {}

  function Guardar() {
    Api(
      "cuentas",
      "UpdateIdentidadVisual",
      { _id: store.cuentaactiva },
      {
        fuente: store.fuente,
        colores: store.colores,
      }
    ).then((res) => {
      if (res.success) {
        message.success("Cambios Guardados");
      } else {
        message.error("Algo no salio bien");
      }
    });
  }
  return (
    <div className="elcontainer">
      <div className="botonesgu">
        {haciendocambios && (
          <>
            <Boton
              onClick={Guardar}
              tipo="link"
              className="m-2"
              texto="Guardar"
            />
            <Boton
              onClick={cancelarCambios}
              tipo="danger"
              className="m-2"
              texto="Cancelar"
            />
          </>
        )}
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div style={{ flex: 1 }}>
          <div className="flex flex-col mt-5">
            <span className="mb-1">Tipografia</span>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Selecciona una tipografia"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              value={store.fuente}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fuentes.map((fuente, index) => {
                return (
                  <Option key={"d" + index} value={fuente}>
                    {fuente}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="flex flex-col mt-5">
            <span className="mb-1">Colores Primarios</span>

            <div className="flex flex-row">
              {store.colores.primarios.map((color, index) => {
                return (
                  <div
                    onClick={() => {
                      setHaciendocambios(true);
                      setPaletaprimarios(true);
                      setPrimarioActivo(index);
                      setColorPaleta(store.colores.primarios[index]);
                    }}
                    style={{ background: store.colores.primarios[index] }}
                    className="border-2"
                  ></div>
                );
              })}
            </div>
            {paletaprimarios && (
              <ClickAwayListener
                onClickAway={() => {
                  setTimeout(() => {
                    setPaletaprimarios(false);
                  }, 100);
                }}
              >
                <div style={{ width: 220 }} className="w-200">
                  <SketchPicker
                    color={colorPaleta}
                    onChange={(e) => {
                      setColorPaleta(e.hex);
                      setStoreActualizarColor(
                        "primarios",
                        primarioActivo,
                        e.hex
                      );
                      // setPaletaprimarios(false);
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className="flex flex-col mt-5">
            <span className="mb-1">Colores Botones</span>

            <div className="flex flex-row">
              {store.colores.botones.map((color, index) => {
                return (
                  <div
                    onClick={() => {
                      setHaciendocambios(true);
                      setPaletabotones(true);
                      setPrimarioActivo(index);
                      setColorPaleta(store.colores.botones[index]);
                    }}
                    style={{ background: store.colores.botones[index] }}
                    className="border-2 border-opacity-10 border-black border-solid w-10 h-10 rounded-full m-2 cursor-pointer "
                  ></div>
                );
              })}
            </div>
            {paletabotones && (
              <ClickAwayListener
                onClickAway={() => {
                  setTimeout(() => {
                    setPaletabotones(false);
                  }, 100);
                }}
              >
                <div style={{ width: 220 }} className="w-200">
                  <SketchPicker
                    color={colorPaleta}
                    onChange={(e) => {
                      setColorPaleta(e.hex);
                      setStoreActualizarColor("botones", primarioActivo, e.hex);
                      // setPaletaprimarios(false);
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className="flex flex-col mt-5">
            <span className="mb-1">Colores Header</span>

            <div className="flex flex-row">
              {store.colores.header.map((color, index) => {
                return (
                  <div
                    onClick={() => {
                      setHaciendocambios(true);
                      setPaletaHeader(true);
                      setPrimarioActivo(index);
                      setColorPaleta(store.colores.header[index]);
                    }}
                    style={{ background: store.colores.header[index] }}
                    className="border-2 border-opacity-10 border-black border-solid w-10 h-10 rounded-full m-2 cursor-pointer "
                  ></div>
                );
              })}
            </div>
            {paletaHeader && (
              <ClickAwayListener
                onClickAway={() => {
                  setTimeout(() => {
                    setPaletaHeader(false);
                  }, 100);
                }}
              >
                <div style={{ width: 220 }} className="w-200">
                  <SketchPicker
                    color={colorPaleta}
                    onChange={(e) => {
                      setColorPaleta(e.hex);
                      setStoreActualizarColor("header", primarioActivo, e.hex);
                      // setPaletaprimarios(false);
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
          <div className="flex flex-col mt-5">
            <span className="mb-1">Colores Menu Barra Lateral</span>

            <div className="flex flex-row">
              {store.colores.header.map((color, index) => {
                return (
                  <div
                    onClick={() => {
                      setHaciendocambios(true);
                      setPaletaBarraLateral(true);
                      setPrimarioActivo(index);
                      setColorPaleta(store.colores.barralateral[index]);
                    }}
                    style={{ background: store.colores.barralateral[index] }}
                    className="border-2 border-opacity-10 border-black border-solid w-10 h-10 rounded-full m-2 cursor-pointer "
                  ></div>
                );
              })}
            </div>
            {paletaBarraLateral && (
              <ClickAwayListener
                onClickAway={() => {
                  setTimeout(() => {
                    setPaletaBarraLateral(false);
                  }, 100);
                }}
              >
                <div style={{ width: 220 }} className="w-200">
                  <SketchPicker
                    color={colorPaleta}
                    onChange={(e) => {
                      setColorPaleta(e.hex);
                      setStoreActualizarColor(
                        "barralateral",
                        primarioActivo,
                        e.hex
                      );
                      // setPaletaprimarios(false);
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!subiendoLogo ? (
            <>
              <img width={200} alt="logo" src={logo} />
              <input
                style={{ marginTop: 10 }}
                accept=".png"
                type="file"
                name="logo"
                onChange={logoSeleccionado}
              />
            </>
          ) : (
            <Progress
              type="circle"
              style={{ maxWidth: 200, marginTop: 10 }}
              percent={progresoSubidaLogo}
            />
          )}

          {selectedFile && !subiendoLogo && (
            <div>
              <Boton
                loading={progresoSubidaLogo}
                style={{ marginTop: 20 }}
                texto="Subir nuevo Logo"
                onClick={actualizarLogo}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PagesConfigDiseñoIdentidadvisual;
