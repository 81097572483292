import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, InputNumber, message, Select, Space } from "antd";
import zonasHorarias from "../../../../../helpers/zonasHorarias";
import { store } from "../../../../../store";

const { Option } = Select;

function CronFormDisparador(props) {
  return (
    <>
      <Form.Item name="dia" initialValue="todoslosdias">
        <Select>
          <Option value="todoslosdias">Todos los dias</Option>
          <Option value="primerdia">Primer dia del mes</Option>
          <Option value="1y15">1 Y 15 de cada mes</Option>
          <Option value="1">Todos los lunes</Option>
          <Option value="2">Todos los martes</Option>
          <Option value="3">Todos los miercoles</Option>
          <Option value="4">Todos los jueves</Option>
          <Option value="5">Todos los viernes</Option>
          <Option value="6">Todos los sabados</Option>
          <Option value="0">Todos los domingos</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="hora"
        label="Hora"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Select showSearch>
          {[...Array(24)].map((e, i) => {
            return <Option value={i}>{i}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="minuto"
        label="Minuto"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Select showSearch>
          {[...Array(60)].map((e, i) => {
            return <Option value={i}>{i}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="zonaHoraria"
        label="Zona Horaria"
        rules={[{ required: true, message: "Campo obligatorio" }]}
      >
        <Select showSearch={true}>
          {zonasHorarias.map((e) => (
            <Option value={e} key={e}>
              {e}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.List name="variablesDisparador">
        {(fields, { add, remove }) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "rgb(243 243 243)",
                padding: 20,
                borderRadius: 5,
              }}
            >
              {fields.map((field, index) => (
                <Space key={field.name}>
                  <div>
                    <FontAwesomeIcon
                      onClick={() => {
                        if (
                          store.etapas.some((e) =>
                            e.tagsConfig.lastags.some(
                              (a) =>
                                a.tipo.split(" ")[0] === "variable" &&
                                a.valor ===
                                  props.form.getFieldValue(
                                    "variablesDisparador"
                                  )[index].nombreVariable
                            )
                          )
                        ) {
                          message.error(
                            "Estas usando esta variable. Primero elimínala de las etapas"
                          );
                        } else {
                          remove(field.name);
                        }
                      }}
                      icon={faMinusCircle}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Form.Item
                    {...field.layout}
                    label="Nombre Variable"
                    normalize={(value) => {
                      return value.split("Vari_").length === 1
                        ? "Vari_" + value
                        : value;
                    }}
                    rules={[
                      { required: true, message: "Campo obligatorio" },
                      {
                        validator: (rule, value) => {
                          if (
                            props.form
                              .getFieldValue("variablesDisparador")
                              .filter(
                                (v) => v?.nombreVariable.trim() === value.trim()
                              ).length > 1
                          ) {
                            return Promise.reject(
                              "Ya existe una variable con ese nombre"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                    key={[field.name, "nombreVariable"]}
                    name={[field.name, "nombreVariable"]}
                  >
                    <Input
                      className="inputBlanco"
                      disabled={
                        props.form.getFieldValue("variablesDisparador")[index]
                          ?.yaGuardado || false
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "tipoVariable"]}
                    {...field.layout}
                    label="Tipo"
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    key={[field.name, "tipoVariable"]}
                  >
                    <Select
                      disabled={
                        props.form.getFieldValue("variablesDisparador")[index]
                          ?.yaGuardado || false
                      }
                      style={{ minWidth: 200 }}
                      showSearch
                    >
                      <Option value="atributoNumero">Atributo Número</Option>
                      <Option value="idAleatorio">ID aleatorio</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return getFieldValue("variablesDisparador")[index]
                        ?.tipoVariable === "numeroAbierto" ? (
                        <Form.Item
                          name={[field.name, "elNumero"]}
                          label="Número"
                          rules={[
                            {
                              required: true,
                              message: "Campo obligatorio",
                            },
                            {
                              type: "number",
                              message: "Solo numeros",
                            },
                          ]}
                        >
                          <InputNumber />
                        </Form.Item>
                      ) : (
                        getFieldValue("variablesDisparador")[index]
                          ?.tipoVariable === "atributoNumero" && (
                          <Form.Item
                            name={[field.name, "elAtributoNumero"]}
                            label="Valor inicial"
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                              {
                                type: "number",
                                message: "Solo numeros",
                              },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                </Space>
              ))}
              <Form.Item noStyle>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: "60%", alignSelf: "center" }}
                >
                  Agregar Variable
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </>
  );
}

export default CronFormDisparador;
