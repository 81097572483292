import { PDFDocument } from "pdf-lib";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import React from "react";
import { Api } from "../../../../../api/configApi";

import { store } from "../../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Space, Slider, Spin } from "antd";
import { UploadClient } from "@uploadcare/upload-client";

function DesignWidgetPdf(props) {


  const [widget, setWidget] = React.useState({});
  const [esCelular, setEsCelular] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [ancho, setAncho] = React.useState(500);
  const [minAncho, setMinAncho] = React.useState(500);
  const [maxAncho, setMaxAncho] = React.useState(500);
  const refBlob = React.useRef();
  const refSignature = React.useRef();
  const refExpire = React.useRef();
  const [docEnviado, setDocEnviado] = React.useState();
  const client = new UploadClient({
    publicKey: "48fb73efa9e179704af8",
    secureSignature:
      "78bfaf77deff13e4fa58d2ea9c1578d06e5d46cd861ce4c6836685747373485a",
    secureExpire: "1653594279",
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const pp = document.getElementsByClassName("ant-layout-content")[0];

    // es un celular
    if (window.innerWidth <= 768) {
      setEsCelular(true);
      setMinAncho(pp.clientWidth - 60);
      setMaxAncho(500);
      setAncho(pp.clientWidth - 60);
    } else {
      setEsCelular(false);
      setMinAncho(500);
      setMaxAncho(window.innerWidth);
    }
  }

  async function autoCompletarPdf(widgetActual) {
    // console.log(widgetActual, "wiget")
    Api(
      "afiliados",
      "GetCampos",
      { widget: widgetActual._id, campos: widgetActual.campos },
      {}
    ).then(async (losCampos) => {
      const existingPdfBytes = await fetch(widgetActual.url).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const fields = form.getFields();

      for (const key in losCampos) {
        const existe = fields.find((field) => field.getName() === key);
        if (existe) {
          const field = form.getTextField(key);
          field.setText(`${losCampos[key]} ${widgetActual.relleno}`);
          field.setFontSize(13);
          // console.log("procesado " + key);
        }
      }
      form.flatten();

      const pdfBytes = await pdfDoc.save();
      var bytes = new Uint8Array(pdfBytes);
      var blob = new Blob([bytes], { type: "application/pdf" });
      refBlob.current = blob;
      const docUrl = URL.createObjectURL(blob);
      setWidget({ ...widgetActual, url: docUrl });
      // console.log("Aquí, autocompletando el PDF");
    });
  }

  function obtenerToken () {
    Api("Listados", "generateToken", {}, {})
      .then((res) => {

      })
      .catch((err) => {
        console.log(err);
      }); 
  }


  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });

    if (widgetActual.tipoPdf === "visual") {
      setWidget(widgetActual);
    }
    if (widgetActual.tipoPdf === "autocompletar") {
      autoCompletarPdf(widgetActual);
    }
  }, [props]);

  React.useEffect(() => {
    if (widget._id) {
      // Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
      //   (res) => {
      //     res.success && setCantidadAfiliado(res.total);
      //   }
      // );
    }
  }, [widget]);

  React.useEffect(() => {
    obtenerToken()
  }, []);

  return (
    <div
      style={{ height: "100%", width: "100%", position: "relative" }}
      className="contenedorPdf"
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: widget.fondo,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        ></div>
        {widget.url ? (
          <>
            <div>
              {widget.descargar && widget.url && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: 10,
                  }}
                >
                  <a href={widget.url} download={widget.nombre}>
                    Descargar
                  </a>
                </div>
              )}
              {widget.firmaDigital && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    marginTop: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    
                    if (refBlob.current && refSignature.current && refExpire.current) {
                      console.log("si entraaaa")
                      client
                        .uploadFile(refBlob.current)
                        .then(
                          (file) =>setDocEnviado(file.originalUrl)
                        );
                    }
                    if(setDocEnviado){
                      console.log("firma fad")
                    }
                  }}
                >
                  Firmar
                </div>
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Document
                  onLoadError={console.error}
                  file={widget.url}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading="Cargando Documento"
                >
                  <Page width={ancho} pageNumber={pageNumber} />
                </Document>
              </div>

              <div>
                <Slider
                  value={ancho}
                  min={minAncho}
                  max={maxAncho}
                  onChange={(e) => {
                    setAncho(e);
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <Space>
                  {pageNumber > 1 && (
                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setPageNumber(pageNumber - 1);
                        }}
                        style={{ fontSize: 30, cursor: "pointer" }}
                        icon={faArrowAltCircleLeft}
                      />
                    </div>
                  )}
                  {pageNumber < numPages && (
                    <div>
                      <FontAwesomeIcon
                        onClick={() => {
                          setPageNumber(pageNumber + 1);
                        }}
                        style={{ fontSize: 30, cursor: "pointer" }}
                        icon={faArrowAltCircleRight}
                      />
                    </div>
                  )}
                </Space>
              </div>
              <p style={{ textAlign: "center" }}>
                Página {pageNumber} de {numPages}
              </p>
            </div>
          </>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </div>
  );
}

export default DesignWidgetPdf;
