import { Form, Input, Select, Spin } from "antd";
import React from "react";

import CoinPayments from "./integraciones/CoinPayments";
import { Api } from "../../../../api/configApi";

const { Option } = Select;

function CamposPersonalizados(props) {
  const refListaIntegraciones = React.useRef(["coinpayments"]);
  const [integracionActual, setIngreacionActual] = React.useState();
  const refListaMonedas = React.useRef();
  const [listaMonedas, setListaMonedas] = React.useState();
  const refListaMonedasArray = React.useRef([]);
  const [listaMonedasArray, setListaMonedasArray] = React.useState();
  const refRuta = React.useRef(process.env.REACT_APP_RUTA);
  const refIntegracion = React.useRef();
  const [integracion, setIntegracion] = React.useState();

  const pedirDatos = () => {
    Api(
      "pasarelas",
      props.formulario.getFieldsValue().listaIntegraciones,
      { type: "getInfoCoins" },
      {},
      refRuta.current
    )
      .then((res) => {
        refIntegracion.current = true;
        setIntegracion(refIntegracion.current);
        let toArray = Object.entries(res);
        toArray.forEach((elem) => {
          refListaMonedasArray.current.push({
            nombreMoneda: elem[1].name,
            codigoMoneda: elem[0],
            isFiat: elem[1].is_fiat,
          });
        });
        setListaMonedasArray(refListaMonedasArray.current);
        refListaMonedas.current = Object.values(res).map(
          (moneda) => moneda.name
        );
        setListaMonedas(refListaMonedas.current);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    props.formulario.getFieldsValue().listaIntegraciones && pedirDatos();
  }, [props]);

  return (
    <>
      <Form.Item
        name="nombreBoton"
        label="Nombre del botón"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Input
          placeholder="Por favor escoge un nombre para tu botón"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        name="listaIntegraciones"
        label="Lista de integraciones"
        rules={[
          {
            required: true,
            message: "Atributo obligatorio",
          },
        ]}
      >
        <Select
          placeholder="Por favor escoge una integración"
          style={{ width: "100%" }}
          onChange={(e) => {
            setIngreacionActual(e);
            pedirDatos();
          }}
        >
          {refListaIntegraciones.current.map((integracion, index) => {
            return (
              <Option key={index} value={integracion}>
                {integracion}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      {integracion ? (
        props.formulario.getFieldsValue().listaIntegraciones ==
          "coinpayments" &&
        listaMonedas && (
          <CoinPayments
            formu={props.formulario}
            listaMonedas={listaMonedas}
            listaMonedasArray={listaMonedasArray}
          />
        )
      ) : (
        <Spin />
      )}
    </>
  );
}

export default CamposPersonalizados;
