import React from "react";
import CrearActualizarWidgetAutoLlenarPdf from "./tipos/Pdf/CrearActualizarWidgetPdfComponente";
import CrearActualizarWidgetIndicadoresComponente from "./tipos/Indicadores/CrearActualizarWidgetIndicadoresComponente";
import CrearActualizarWidgetTranferNumero from "./tipos/TransferNumero/CrearActualizarWidgetTranferNumeroComponente";

function TiposFormulariosWidgetsDev1(props) {
  React.useEffect(() => {}, []);
  return (
    <>
      {(props.tipo === "pdf" || props.mostrar === "pdf") && (
        <CrearActualizarWidgetAutoLlenarPdf
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
      {(props.tipo === "indicadores" || props.mostrar === "indicadores") && (
        <CrearActualizarWidgetIndicadoresComponente
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
      {(props.tipo === "tranferNumero" ||
        props.mostrar === "tranferNumero") && (
        <CrearActualizarWidgetTranferNumero
          visible={props.visible}
          setVisible={props.setVisible}
          data={props.data}
        />
      )}
    </>
  );
}

export default TiposFormulariosWidgetsDev1;
