import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Typography,
} from "antd";
import FormList from "antd/lib/form/FormList";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const CamposPersonalizados = () => {
  const refexpNatural = React.useRef(/^\d+$/);
  const refContractTypeArr = React.useRef(["Contrato", "Solicitud", "Acuse"]);
  const refPhoneCodeArr = React.useRef([
    93, 358, 355, 213, 1684, 376, 244, 1264, 672, 1268, 54, 374, 297, 61, 43,
    994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975, 591, 599, 387, 267,
    55, 55, 246, 673, 359, 226, 257, 855, 237, 1, 238, 1345, 236, 235, 56, 86,
    61, 672, 57, 269, 242, 242, 682, 506, 225, 385, 53, 599, 357, 420, 45, 253,
    1767, 1809, 593, 20, 503, 240, 291, 372, 251, 500, 298, 679, 358, 33, 594,
    689, 262, 241, 220, 995, 49, 233, 350, 30, 299, 1473, 590, 1671, 502, 44,
    224, 245, 592, 509, 0, 39, 504, 852, 36, 354, 91, 62, 98, 964, 353, 44, 972,
    39, 1876, 81, 44, 962, 7, 254, 686, 850, 82, 381, 965, 996, 856, 371, 961,
    266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692,
    596, 222, 230, 269, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 95, 264,
    674, 977, 31, 599, 687, 64, 505, 227, 234, 683, 672, 1670, 47, 968, 92, 680,
    970, 507, 675, 595, 51, 63, 64, 48, 351, 1787, 974, 262, 40, 70, 250, 590,
    290, 1869, 1758, 590, 508, 1784, 684, 378, 239, 966, 221, 381, 381, 248,
    232, 65, 1, 421, 386, 677, 252, 27, 500, 211, 34, 94, 249, 597, 47, 268, 46,
    41, 963, 886, 992, 255, 66, 670, 228, 690, 676, 1868, 216, 90, 7370, 1649,
    688, 256, 380, 971, 44, 1, 1, 598, 998, 678, 58, 84, 1284, 1340, 681, 212,
    967, 260, 263,
  ]);

  return (
    <>
      <Divider />

      <Form.Item
        name={["requisitionObj", "requisition", "contractName"]}
        label="Escribe el nombre del contrato"
        rules={[
          { required: true, message: "Debes escribir el nombre del contrato." },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "acceptanceLegend"]}
        initialValue=""
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "idDocument"]}
        initialValue=""
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "acceptanceVideoNotRequired"]}
        initialValue={true}
      ></Form.Item>

      <Form.Item
        name={["requisitionObj", "requisition", "validity"]}
        label="Escribe los días en que permanecerá el documento activo para los firmantes"
        rules={[
          {
            required: true,
            message:
              "Debes escribir los días en que permanecerá el documento activo.",
          },
        ]}
        initialValue={1}
      >
        <InputNumber min={1} max={5} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name={["requisitionObj", "requisition", "contractType"]}
        label="Seleccione el tipo de contrato"
        rules={[
          { required: true, message: "Debes selecionar el tipo de contrato." },
        ]}
      >
        <Select
          showSearch
          placeholder="Tipo de contrato"
          style={{ textAlign: "left" }}
        >
          {refContractTypeArr.current.map((tipoContrato, index) => {
            return (
              <Select.Option key={index} value={tipoContrato}>
                {tipoContrato}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "page"]}
        initialValue={1}
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "positionX1"]}
        initialValue={8.48066}
      ></Form.Item>
      <Form.Item
        name={["requisitionObj", "requisition", "certificate", "positionX2"]}
        initialValue={88.48066}
        hidden
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "positionY1"]}
        initialValue={78.4089}
      ></Form.Item>
      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "certificate", "positionY2"]}
        initialValue={90.53880000000001}
      ></Form.Item>

      <FormList name={["requisitionObj", "requisition", "signers"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Divider />
                  <Typography.Title level={4}>
                    Firmante {name + 1}
                  </Typography.Title>

                  <Form.Item
                    {...restField}
                    label="Escribe tú nombre y tú apellido. Ejemplo:(Nombre Apellido)"
                    name={[name, "signerName"]}
                    rules={[
                      {
                        required: true,
                        message: "El nombre y el apellido son necesarios",
                      },
                      () => ({
                        validator(_, value) {
                          if (
                            value
                              ?.trim()
                              .split("")
                              .find((letra) => letra === " ") === " "
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "El nombre y el apellido que escribiste van separados por un espacio."
                            )
                          );
                        },
                      }),
                    ]}
                    style={{ marginBottom: "1px" }}
                  >
                    <Input minLength={1} maxLength={50} />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label="Escribe tú correo electrónico "
                    name={[name, "mail"]}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Debes escribir tú correo.",
                      },
                    ]}
                    style={{ marginBottom: "1px" }}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Selecciona el código de tú país de residencia y escribe tú número de teléfono 1° actual"
                    name={[name, "phone"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir tú número de teléfono.",
                      },
                      () => ({
                        validator(_, value) {
                          value = value.match(refexpNatural.current);
                          if (value !== null) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Debes esbcribir solo números")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      addonBefore={
                        <Form.Item
                          name={[name, "countryCode"]}
                          noStyle
                          initialValue="+52"
                          rules={[
                            {
                              require: true,
                              message:
                                "Seleccione el código de teléfono de tú país",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ textAlign: "left", width: "90px" }}
                          >
                            {refPhoneCodeArr.current.map((phoneCode, index) => {
                              return (
                                <Select.Option
                                  key={index}
                                  value={`+${phoneCode}`}
                                >
                                  {`+${phoneCode}`}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Selecciona el código de tú país y escribe tú segundo número de teléfono "
                    name={[name, "signDevicePhone"]}
                    rules={[
                      {
                        required: true,
                        message:
                          "Debes escribir tú segundo número de teléfono.",
                      },
                      () => ({
                        validator(_, value) {
                          value = value.match(refexpNatural.current);
                          if (value !== null) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Debes esbcribir solo números")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      addonBefore={
                        <Form.Item
                          rules={[
                            {
                              require: true,
                              message:
                                "Seleccione el código de teléfono de tú país",
                            },
                          ]}
                          name={[name, "signDeviceCountryCode"]}
                          noStyle
                        >
                          <Select
                            showSearch
                            style={{ textAlign: "left", width: "90px" }}
                          >
                            {refPhoneCodeArr.current.map((phoneCode, index) => {
                              return (
                                <Select.Option
                                  key={index}
                                  value={`+${phoneCode}`}
                                >
                                  {`+${phoneCode}`}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Escribe tú código de seguridad que llegó a tú teléfono"
                    name={[name, "authenticationType"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir tú código de seguridad",
                      },
                    ]}
                  >
                    <Input min={0} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    label="Escribe los cuatro últimos números de tú teléfono actual"
                    name={[name, "authenticationData"]}
                    rules={[
                      {
                        required: true,
                        message:
                          "Dedes escribir los cuatro últimos de tu teléfono",
                      },
                      ({ getFieldsValue }) => ({
                        validator(_, value) {
                          const numTelefono = String(
                            getFieldsValue().requisitionObj.requisition.signers[
                              name
                            ].phone
                          );
                          if (
                            numTelefono?.slice(
                              numTelefono.length - 4,
                              numTelefono.length
                            ) === String(value)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "Los números que escribiste no coinciden."
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input min={0} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    label="Orden"
                    hidden
                    name={[name, "order"]}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    initialValue={name + 1}
                  ></Form.Item>
                  <Divider />

                  <Typography.Title level={4}>
                    Coordenadas del Campo de la Firma
                  </Typography.Title>

                  <Form.Item
                    name={[name, "signatures", "centerX"]}
                    label="Escribe la primera coordenada, si es decimal va con punto " // (x2)
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>

                  <Form.Item
                    name={[name, "signatures", "centerY"]}
                    hidden
                    initialValue={0}
                  ></Form.Item>

                  <Form.Item
                    hidden
                    name={[name, "signatures", "page"]}
                    initialValue={name + 1}
                  ></Form.Item>

                  <Form.Item
                    name={[name, "signatures", "positionX1"]}
                    label="Escribe la segunda coordenada, si es decimal va con punto " // (x1)
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>

                  <Form.Item
                    name={[name, "signatures", "positionX2"]}
                    hidden
                    initialValue={0}
                  ></Form.Item>
                  <Form.Item
                    name={[name, "signatures", "positionY2"]}
                    label="Escribe la tercera coordenada, si es decimal va con punto " // (y2)
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la coordenada.",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                  <Form.Item
                    name={[name, "signatures", "positionY1"]}
                    hidden
                    initialValue={0}
                  ></Form.Item>
                  <Form.Item
                    name={[name, "signatures", "signerType"]}
                    label="Escribe tú rol como firmante del contrato" // (x2)
                    initialValue=""
                  >
                    <Input />
                  </Form.Item>
                  <Space size="small">
                    <Form.Item
                      hidden
                      name={[name, "inePictureFront"]}
                      initialValue={false}
                    ></Form.Item>
                    <Form.Item
                      hidden
                      name={[name, "inePictureBack"]}
                      initialValue={false}
                    ></Form.Item>
                    <Form.Item
                      hidden
                      name={[name, "compareIneSelfie"]}
                      initialValue={false}
                    ></Form.Item>
                    <Form.Item
                      hidden
                      name={[name, "compareSelfieProof"]}
                      initialValue={false}
                    ></Form.Item>
                    <Form.Item
                      hidden
                      name={[name, "idType"]}
                      initialValue={name + 1}
                    ></Form.Item>
                  </Space>
                  <Button
                    onClick={() => remove(name)}
                    style={{ marginBottom: "12px" }}
                    type="dashed"
                  >
                    Eliminar Firmante <MinusCircleOutlined />{" "}
                  </Button>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar firmantes
              </Button>
            </Form.Item>
          </>
        )}
      </FormList>

      <Form.Item
        hidden
        name={["requisitionObj", "requisition", "signOnWeb"]}
        initialValue={true}
      ></Form.Item>

      <FormList
        name={["requisitionObj", "requisition", "mailResend"]}
        initialValue={[]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Form.Item
                    {...restField}
                    label="Escribe tú correo electrónico adicional"
                    name={[name, "email"]}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Debes escribir tú correo electrónico",
                      },
                    ]}
                    style={{ marginBottom: "1px" }}
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Button
                    onClick={() => remove(name)}
                    type="dashed"
                    style={{ marginBottom: "12px" }}
                  >
                    Eliminar Correo electrónico <MinusCircleOutlined />{" "}
                  </Button>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {"Agregar más correos electrónicos (Opcional)"}
              </Button>
            </Form.Item>
          </>
        )}
      </FormList>

      <FormList
        name={["requisitionObj", "requisition", "extraInfo"]}
        initialValue={[]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space
                  key={key}
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Form.Item
                    {...restField}
                    label="Escribe la informacion extra que crees necesaria"
                    name={[name, "extraInfo.property"]}
                    rules={[
                      {
                        required: true,
                        message: "Debes escribir la información extra",
                      },
                    ]}
                    style={{ marginBottom: "1px" }}
                  >
                    <TextArea style={{ width: "100%" }} />
                  </Form.Item>
                  <Button
                    onClick={() => remove(name)}
                    type="dashed"
                    style={{ marginBottom: "12px" }}
                  >
                    Eliminar información extra <MinusCircleOutlined />{" "}
                  </Button>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                style={{ marginBottom: "12px" }}
              >
                {"Agregar información extra (Opcional)"}
              </Button>
            </Form.Item>
          </>
        )}
      </FormList>
    </>
  );
};

export default CamposPersonalizados;
