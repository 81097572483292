import React from "react";

import { store } from "../../../../../store";
import { Api } from "../../../../../api/configApi";
import { Progress } from "antd";

function DesignWidgetObjetivo(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState();
  const [porcentaje, setPorcentaje] = React.useState(0);
  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);
  React.useEffect(() => {
    // console.log(widget);
    if (widget._id) {
      Api("atributosNumero", "GetTotal", { widget: widget._id }, {}).then(
        (res) => {
          // var formatter = new Intl.NumberFormat("en-ES", {
          //   style: "currency",
          //   currency: "BTC",
          // });
          res.success ? setContenido(res.total) : setContenido(0);
          
        }
      );
    }
  }, [widget]);

  return (
    <div style={{padding: 20}}>
      <span>{widget.titulo || ""}</span>
      <div style={estilos.contenido}>
        <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
          <Progress type="line" style={{ marginTop: 15,  }}  percent={parseFloat((contenido / widget.objetivo)*100).toFixed(1)} />
          <span style={{fontSize: 12, marginTop: 5}}>{contenido} de {widget.objetivo}</span>
        </div>
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default DesignWidgetObjetivo;
