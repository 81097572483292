import { Form, Input, InputNumber, message, Space } from "antd";
import { MotionConfig } from "framer-motion";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../design/elementos/boton";

const rules = [{ required: true, message: "Campo obligatorio" }];

function FormSolicitarDebitoCredito({ widget, setDrawerSolicitud, Get }) {
  const [guardando, setGuardando] = React.useState(false);
  const [monto, setMonto] = React.useState(widget.minimo);
  React.useEffect(() => {
    // console.log(widget);
  }, []);

  function solicitar(values) {
    if (
      values.monto < widget.minimo ||
      (values.monto > widget.maximo && widget.maximo !== 0)
    ) {
      message.error("Monto minimo o máximo alcanzado");
      return;
    }
    setGuardando(true);
    Api(
      "multiData",
      "InsertDebitoCredito",
      { widget: widget._id },
      values
    ).then((res) => {
      setGuardando(false);
      if (res.success) {
        Get(widget);
        message.success("Solicitud enviada");
        setDrawerSolicitud(false);
      } else {
        setGuardando(false);
        message.error(res.message || "Algo no salio bien");
      }
    });
  }
  return (
    <>
      <Form onFinish={solicitar} layout="vertical">
        <Form.Item
          initialValue={widget.minimo}
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="monto"
          label="Monto a retirar"
        >
          <InputNumber
            style={{ width: "100%" }}
            onChange={(e) => setMonto(e)}
          />
        </Form.Item>
        <Space direction="vertical">
          <Space>
            <span>
              Fee:{" "}
              <strong>
                {(monto * widget.feePorcentaje) / 100 + widget.feeUnidad}
              </strong>
            </span>
            <span>
              Recibirás{" "}
              <strong>
                {monto -
                  ((monto * widget.feePorcentaje) / 100 + widget.feeUnidad)}
              </strong>
            </span>
          </Space>
          <Boton loading={guardando} texto="Guardar" htmlType="submit" />
        </Space>
      </Form>
      <div style={{ marginTop: 20, fontSize: 13, fontWeight: 300 }}>
        <p>Minimo: {widget.minimo}</p>
        <p>Máximo: {widget.maximo > 0 ? widget.maximo : "Sin limite"}</p>

        <p>{widget.indicaciones || ""}</p>
      </div>
    </>
  );
}

export default FormSolicitarDebitoCredito;
