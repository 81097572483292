import DesignWidgetPdf from "./tipos/Pdf/design/DesignWidgetPdf";
import IndicadoresDesignWidget from "./tipos/Indicadores/indicadoresDesignWidget";
import DesignWidgetTranferNumero from "./tipos/TransferNumero/design/DesignWidgetTranferNumero"

function DesignWidgetsComponentes(props) {

  // console.log(props, "props de design")

  var widget;
  switch (props.widget.tipo) {
    case "pdf":
      widget = <DesignWidgetPdf {...props.widget} />;
      break;
    case "indicadores":
      widget = <IndicadoresDesignWidget {...props.widget} />;
      break;
    case "tranferNumero":
      widget = <DesignWidgetTranferNumero {...props.widget} />;
      break;

    default:
      break;
  }
  return widget;
}

export default DesignWidgetsComponentes;
