import React from "react";
import DesignWidgetNumero from "../../../../components/widgets/tipos/numero/design/DesignWidgetNumero";
import DesignWidgetLink from "../../../../components/widgets/tipos/link/design/DesignWidgetLink";
import DesignWidgetArbolGenealogico from "../../../../components/widgets/tipos/arbolGenealogico/design/DesignWidgetArbolGenealogico";
import DesignWidgetWoocommerce from "../../../../components/widgets/tipos/woocommerce/design/DesignWidgetWoocommerce";
import { isMobile } from "react-device-detect";
import AgregarWidgetaFilaComponente from "./AgregarWidgetaFilaComponente";
import { Input, Popover } from "antd";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import DesignWidgetAdminRecursos from "../../../../components/widgets/tipos/Admin Recursos/design/DesignWidgetAdminRecursos";
import DesignWidgetListaRecursos from "../../../../components/widgets/tipos/Lista recursos/design/DesignWidgetListaRecursos";
import DesignWidgetDebitoCredito from "../../../../components/widgets/tipos/debitoCredito/design/DesignWidgetDebitoCredito";
import DesignWidgetTexto from "../../../../components/widgets/tipos/texto/design/DesignWidgetTexto";
import DesignWidgetObjetivo from "../../../../components/widgets/tipos/objetivo/design/DesignWidgetObjetivo";
import DesignWidgetBanner from "../../../../components/widgets/tipos/banner/design/DesignWidgetBanner";
import DesignWidgetLogros from "../../../../components/widgets/tipos/logros/design/DesignWidgetLogros";

import DesignWidgetVideo from "../../../../components/widgets/tipos/video/design/DesignWidgetVideo";
import DesignWidgetUploadFiles from "../../../../components/widgets/tipos/uploadFiles/design/DesignWidgetUploadFiles";
import DesignWidgetPasarelas from "../../../../components/widgets/tipos/Pasarelas/design/DesignWidgetPasarelas";
import DesignWidgetsComponentes from "../../../../frontDEV1/widgets/DesignWidgetsComponentes";
import DesignWidgetTabla from "../../../../frontDEV1/widgets/tipos/tabla/design/DesignWidgetTabla";

function DibujarWidgetComponente(props) {
  const [flex, setFlex] = React.useState(props.widget.flex || 0);

  const MoveraIzquierda = () => {
    const newWidgets = [...props.widgets];
    const widgetAMover = { ...newWidgets[props.indexfila][props.indexWidget] };
    newWidgets[props.indexfila].splice(props.indexWidget, 1);
    newWidgets[props.indexfila].splice(props.indexWidget - 1, 0, widgetAMover);
    props.setWidgets(newWidgets);
  };
  const MoveraDerecha = () => {
    const newWidgets = [...props.widgets];
    const widgetAMover = { ...newWidgets[props.indexfila][props.indexWidget] };
    newWidgets[props.indexfila].splice(props.indexWidget, 1);
    newWidgets[props.indexfila].splice(props.indexWidget + 1, 0, widgetAMover);
    props.setWidgets(newWidgets);
  };

  const cambiarPrioridad = (valor) => {
    props.widget.flex = +valor;
    setFlex(+valor);
    // verificar si es un celular
  };

  React.useEffect(() => {
    isMobile && setFlex("auto");
  }, []);

  const EliminarWidget = () => {
    const newWidgets = [...props.widgets];
    newWidgets[props.indexfila].splice(props.indexWidget, 1);
    if (newWidgets[props.indexfila].length === 0) {
      newWidgets.splice(props.indexfila, 1);
    }
    props.setWidgets(newWidgets);
  };
  const content = (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <strong style={{ marginBottom: 5 }}>Opciones Fila</strong>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        ></div>
      </div>
      <AgregarWidgetaFilaComponente
        indexfila={props.indexfila}
        widgets={props.widgets}
        setWidgets={props.setWidgets}
        fila={props.fila}
      />
    </>
  );
  const Widget = React.useCallback(() => {
    var widget;
    switch (props.widget.tipo) {
      case "numero":
        widget = <DesignWidgetNumero {...props.widget} />;
        break;
      case "texto":
        widget = <DesignWidgetTexto {...props.widget} />;
        break;
      case "objetivo":
        widget = <DesignWidgetObjetivo {...props.widget} />;
        break;
      case "link":
        widget = <DesignWidgetLink {...props.widget} />;
        break;
      case "woocommerce":
        widget = <DesignWidgetWoocommerce {...props.widget} />;
        break;
      case "tabla":
        widget = <DesignWidgetTabla {...props.widget} />;
        break;
      case "arbolGenealogico":
        widget = <DesignWidgetArbolGenealogico {...props.widget} />;
        break;
      case "adminRecursos":
        widget = <DesignWidgetAdminRecursos {...props.widget} />;
        break;
      case "listaRecursos":
        widget = <DesignWidgetListaRecursos {...props.widget} />;
        break;
      case "debitoCredito":
        widget = <DesignWidgetDebitoCredito {...props.widget} />;
        break;
      case "uploadFiles":
        widget = <DesignWidgetUploadFiles {...props.widget} />;
        break;
      case "banner":
        widget = <DesignWidgetBanner {...props.widget} />;
        break;
      case "logros":
        widget = <DesignWidgetLogros {...props.widget} />;
        break;

      case "video":
        widget = <DesignWidgetVideo {...props.widget} />;
        break;
      case "pasarelas":
        widget = <DesignWidgetPasarelas {...props.widget} />;
        break;
      default:
        break;
    }
    if (!widget) {
      widget = <DesignWidgetsComponentes widget={props.widget} />;
    }

    return widget;
  }, []);

  return (
    <>
      {props.produccion ? (
        <motion.div
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="widgetShadow widget"
          style={{ ...estilo.widget, flex: flex || "1 1 auto" }}
        >
          <Widget />
        </motion.div>
      ) : (
        <Popover
          trigger="contextMenu"
          content={props.tipoMenu !== "app" ? content : ""}
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <strong style={{ marginBottom: 5 }}>Opciones Widget</strong>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 5,
                  }}
                >
                  <span>Eliminar Widget</span>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={EliminarWidget}
                    icon={faTrash}
                  />
                </div>
                {props.tipoMenu !== "app" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: 5,
                    }}
                  >
                    <span>Prioridad</span>
                    <Input
                      style={{ width: 100 }}
                      type="number"
                      value={flex}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 20) {
                          cambiarPrioridad(e.target.value);
                        }
                      }}
                      min={0}
                      max={20}
                    />
                  </div>
                )}

                {props.indexWidget > 0 && props.tipoMenu !== "app" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: 5,
                    }}
                  >
                    <span>Mover widget a la izquierda</span>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={MoveraIzquierda}
                      icon={faArrowLeft}
                    />
                  </div>
                )}
                {props.indexWidget + 1 <
                  props.widgets[props.indexfila].length && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: 5,
                    }}
                  >
                    <span>Mover widget a la derecha</span>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={MoveraDerecha}
                      icon={faArrowRight}
                    />
                  </div>
                )}
              </div>
            </div>
          }
        >
          <motion.div
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="widgetShadow widget"
            style={{ ...estilo.widget, flex: flex || "1 1 auto" }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                margin: "auto",
                zIndex: 1,
              }}
            ></div>

            <Widget />
          </motion.div>
        </Popover>
      )}
    </>
  );
}

const estilo = {
  widget: {
    display: "flex",
    flexDirection: "column",
    minWidght: 100,
    background: "#fff",
    borderRadius: 3,
    fontWeight: 300,
    flexGrow: 3,
    opacity: 0,
    position: "relative",
  },
};

export default DibujarWidgetComponente;
