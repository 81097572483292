import { Form, Input, message } from "antd";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../../components/design/elementos/boton";

function IntegracionSendGrid(values) {
  function guardar(values) {
    Api(
      "Cuentas",
      "InsertIntegracion",
      { tipoIntegracion: "sendEmail", proveedor: "sendgrid" },
      values
    ).then((res) => {
      if (res.success) {
        message.success("Integración SendGrid Guardad");
      } else {
        message.error("Algo no salio bien.");
      }
    });
  }
  return (
    <Form onFinish={guardar} layout="vertical">
      <Form.Item
        rules={[
          { required: true, message: "campo obligatorio" },
          { min: 1, message: "Parece una api incorrecta" },
          { pattern: /^\S*$/, message: "Parece una api incorrecta" },
        ]}
        label="Api Key"
        name="apiKey"
        initialValue="Por seguridad la key guardada no se puede mostrar"
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "campo obligatorio" }]}
        label="Email remitente"
        name="email"
      >
        <Input type="email" />
      </Form.Item>
      <Boton texto="Guardar" htmlType="submit" />
    </Form>
  );
}

export default IntegracionSendGrid;
