import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faGlasses, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Space, Table } from "antd";
import React from "react";
import { Api } from "../api/configApi";
import Boton from "../components/design/elementos/boton";
import CamposRequeridosModuloComponente from "../components/modulos/CamposRequeridosModuloComponente";
import InfoHelperModuloComponente from "../components/modulos/InfoHelperModuloComponente";
import ListaModulosDisponibles from "../components/modulos/ListaModulosDisponiblesComponente";
import LLength from "../helpers/LLength";
import { store } from "../store";

function ModulosPage() {
  const [modulos, setModulos] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [moduloEditar, setModuloEditar] = React.useState({});
  const [editarModulo, setEditarModulo] = React.useState(false);
  const [drawerHelper, setDrawerHelper] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Opciones",
      dataIndex: "opciones",
      key: "opciones",
      render: (text, record) => (
        <Space key={text} size="middle">
          {
            record.campos.length > 0 && <FontAwesomeIcon onClick={() => { setModuloEditar(record); setEditarModulo(true); }} style={{ cursor: "pointer" }} icon={faEdit} />
          }
          {
            LLength(record, ["informacion"]) > 0 && <FontAwesomeIcon onClick={() => { setModuloEditar(record); setDrawerHelper(true) }} style={{ cursor: "pointer" }} icon={faGlasses} />
          }
          <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} />

        </Space>
      ),
    },
  ];
  React.useEffect(() => {
    Api("cuentas", "Get", { _id: store.cuentaactiva }, { modulos: 1 }).then(
      (res) => {
        setModulos(res[0].modulos);
      }
    );
  }, [visible]);
  return (
    <>
      <Drawer
        title="Información"
        placement="right"
        closable={true}
        onClose={() => { setDrawerHelper(false) }}
        visible={drawerHelper}
        width={800}
      >
        <InfoHelperModuloComponente modulo={moduloEditar} />
      </Drawer>
      <Drawer
        title="Campos Requeridos"
        placement="right"
        closable={true}
        onClose={() => { setEditarModulo(false) }}
        visible={editarModulo}
        width={800}
      >
        <CamposRequeridosModuloComponente actualizar={true} setVisible={setEditarModulo} modulo={moduloEditar} />
      </Drawer>
      <div style={{ marginTop: 30 }}></div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Boton onClick={showDrawer} texto="Instalar Modulo" />
      </div>
      <Drawer
        title="Modulos Disponibles"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={1080}
      >
        <ListaModulosDisponibles onClose={onClose} modulos={modulos} />
      </Drawer>
      <Table columns={columns} dataSource={modulos} />
    </>
  );
}

export default ModulosPage;
