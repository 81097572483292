import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/core/Alert";
import { store, setUser } from "../../store";
import { Api, Auth } from "../../api/configApi";
import { Input, message, Skeleton, Form, Drawer } from "antd";
import RecuperarContrasena from "../../components/ cuenta/recuperarContrasena";

function Nologueado(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [toast, setToast] = React.useState(false);
  const [textoError, setTextoError] = React.useState("");
  const [pensando, setPensando] = React.useState(false);
  const [drawerContra, setDrawerContra] = React.useState(false);

  function ingresar() {
    //eslint-disable-next-line
    const emailbien =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
        email
      );
    const passbien = password.length > 6;

    if (emailbien && passbien) {
      setPensando(true);
      Auth("login", {
        email: email,
        password: password,
        cuenta: store.cuentaactiva,
      }).then((res) => {
        if (res.data.sucess) {
          localStorage.setItem("token", res.data.token);
          setUser(res.data.id);
        } else {
          setToast(true);
          setTextoError(
            "No pudimos loguearte revisa tus datos, intentalo nuevamente o contacta con soporte"
          );
          setPensando(false);
        }
      });
    } else {
      setToast(true);
      setPensando(false);
      !emailbien && setTextoError("El Email no está bien escrito");
      !passbien && setTextoError("La contraseña es muy corta");
    }
  }

  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Backdrop
        style={{ zIndex: 2 }}
        open={pensando}
        onClick={() => {
          setPensando(false);
        }}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast}
        onClose={() => {
          setToast(false);
        }}
        autoHideDuration={4000}
      >
        <Alert severity="error">{textoError}</Alert>
      </Snackbar>
      <div
        style={{
          marginBottom: 10,
          flexDirection: "column",
          display: "flex",
          margin: "auto",
          alignItems: "center",
        }}
      >
        {store.logo && <img alt="logo" width={150} src={store.logo} />}
        <br />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 300,
          marginTop: 10,
        }}
      >
        <Form>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input
              required={true}
              style={{ marginTop: 10 }}
              value={email}
              placeholder="Email"
              variant="outlined"
              type="email"
              onChange={(va) => {
                setEmail(va.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input
              style={{ marginTop: 15 }}
              placeholder="Contraseña"
              value={password}
              variant="outlined"
              onChange={(va) => setPassword(va.target.value)}
              type="password"
            />
          </Form.Item>

          <Button
            type="htmlSubmit"
            onClick={() => {
              ingresar();
            }}
            style={{
              background: "#ffffff",
              boxShadow: "none",
              color: "#000000",
              margin: "auto",
              width: "100%",
            }}
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>
        </Form>
      </div>
      <span
        style={{
          marginTop: 40,
          fontWeight: 200,
          cursor: "pointer",
          fontSize: 12,
          color: "#999",
        }}
        onClick={() => {
          setDrawerContra(true);
        }}
      >
        Recuperar contraseña
      </span>
      <Drawer
        title="Recuperar Contraseña"
        visible={drawerContra}
        onClose={() => {
          setDrawerContra(false);
        }}
      >
        <RecuperarContrasena setDrawerContra={setDrawerContra} />
      </Drawer>
    </div>
  );
}

export default Nologueado;
