import React from "react";
import Tree from "react-d3-tree";

import { store } from "../../../../../store";
import { Button, Popover, Alert, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../../api/configApi";
import DefaultDesignArbol from "./plantillas/defaultDesignArbol";
import PorNivelesDesignArbol from "./plantillas/porNivelesDesignArbol";

function DesignWidgetArbolGenealogico(props) {
  const [widget, setWidget] = React.useState({});
  const [plantilla, setPlantilla] = React.useState();

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setPlantilla(widgetActual.plantilla);
    setWidget(widgetActual);
  }, [props]);

  return (
    <div style={{ padding: 20 }}>
      <div style={estilos.contenido}>
        {plantilla === "porNiveles" ? (
          <PorNivelesDesignArbol widget={widget} />
        ) : (
          <DefaultDesignArbol widget={widget} />
        )}
      </div>
    </div>
  );
}
const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};
export default DesignWidgetArbolGenealogico;
