import { Button } from "antd";
import React from "react";
import { store } from "../../../store";
import { observer } from "mobx-react";
import { motion } from "framer-motion";
import hexRgb from "hex-rgb";

function Boton(props) {
  const [background, setBackground] = React.useState(store.colores.botones[0]);
  const [colorTexto, setColorTexto] = React.useState("#fff");
  function cambiarColorTexto(colore) {
    var rgb = hexRgb(colore);
    var calucl = rgb.red + rgb.green + rgb.blue;
    if (calucl <= 400) {
      setColorTexto("#fff");
    } else {
      setColorTexto("rgb(56 56 56)");
    }
  }
  const estilos = {
    background: background,
    color: colorTexto,
    border: "none",
    padding: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 4,
    outline: "none",
    cursor: "pointer",
  };
  React.useEffect(() => {
    var cole;
    switch (props.tipo) {
      case "link":
        cole = store.colores.botones[0];
        setBackground(cole);
        cambiarColorTexto(cole);
        break;
      case "success":
        cole = store.colores.botones[1];
        setBackground(cole);
        cambiarColorTexto(cole);
        break;
      case "warning":
        cole = store.colores.botones[2];
        setBackground(cole);
        cambiarColorTexto(cole);
        break;
      case "danger":
        cole = store.colores.botones[3];
        setBackground(cole);
        cambiarColorTexto(cole);
        break;
      default:
        break;
    }
  }, [store.colores]);
  return props.disabled ? (
    <Button
      disabled={props.disabled}
      loading={props.loading}
      onClick={props.onClick}
      htmlType={props.htmlType}
      className={props.className}
      style={{ ...estilos, ...props.style, opacity: 0.3 }}
    >
      {props.texto}
    </Button>
  ) : (
    <Button
      disabled={props.disabled}
      loading={props.loading}
      onClick={props.onClick}
      htmlType={props.htmlType}
      className={props.className}
      style={{ ...estilos, ...props.style }}
    >
      {props.texto}
    </Button>
  );
}

export default observer(Boton);
