import React from "react";

import { setPensando, store } from "../../../../../store";
import { Api } from "../../../../../api/configApi";
import WoocommerceShop from "./WoocommerceShop";
import { message, Skeleton } from "antd";

function DesignWidgetWoocommerce(props) {
  const [widget, setWidget] = React.useState({});
  const [productos, setProductos] = React.useState([]);
  const [cargandoProductos, setCargandoProductos] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [buscando, setBuscando] = React.useState(true);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = React.useState(0);
  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);
  function BuscarProductos({ category }) {
    if (category) {
      setCategoriaSeleccionada(category);
    } else {
      setCategoriaSeleccionada([]);
    }
    setBuscando(true);
    if (widget._id) {
      Api(
        "modulos",
        "woocommerce",
        {
          consulta: "GetProductos",
          search: search,
          idWidget: widget._id,
          category: category || "",
        },
        {}
      ).then((res) => {
        setBuscando(false);
        if (typeof res === "object") {
          setProductos(res);
          setCargandoProductos(false);
        } else {
          message.error("No pudimos cargar los productos");
        }
      });
    }
  }
  React.useEffect(() => {
    if (search === "") {
      BuscarProductos({});
    }
  }, [widget, search]);

  return (
    <div style={{padding: 20}}>
      {widget.estilo === "shop" &&
        (cargandoProductos ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton.Avatar active={true} size="large" shape="circle" />
            <Skeleton.Input
              style={{ width: 200, marginTop: 5 }}
              active={true}
              size="large"
            />
            <Skeleton.Input
              style={{ width: 200, marginTop: 5 }}
              active={true}
              size="large"
            />
          </div>
        ) : (
          <div>
            <WoocommerceShop
              categoriaSeleccionada={categoriaSeleccionada}
              buscando={buscando}
              setSearch={setSearch}
              search={search}
              BuscarProductos={BuscarProductos}
              widget={widget}
              productos={productos}
            />
          </div>
        ))}
    </div>
  );
}

export default DesignWidgetWoocommerce;
