import axios from "axios";
import { notification } from "antd";

// desarrollo

const config = {
  URI: process.env.REACT_APP_API_API,
};

// produccion

// const config = {
//   URI: "https://anqnletz7c.execute-api.us-east-1.amazonaws.com/prod/api/",
// };

const instance = axios.create({
  baseURL: config.URI,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
});

const Api = async function (collection, type, datos, accion) {
  // console.log(collection, type, datos, accion);
  return new Promise((resp, err) => {
    // console de la hora actual para ver cuando se hace la peticion

    instance.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token"
    )}`;

    var data = {};
    data.type = type;
    data.data = [datos || {}];
    // El acción en GET representa el PROJECT
    data.accion = accion || {};

    instance
      .post(collection, data)
      .then(function (response) {
        // console.log("O SIII?");
        if (response.data.hasOwnProperty("data")) {
          resp(response.data.data);
          if (response.data.success && response.data.msg) {
            notification["success"]({
              message: "Procesado",
              description: response.data.msg,
            });
          } else if (!response.data.success) {
            notification["error"]({
              message: "Algo salio mal",
              description:
                response.data.msg ||
                "No pudimos procesar la solicitud. Intentalo nuevamente o contacta con soporte",
            });
          }
        } else {
          resp(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        err(error);
      });
  });
};

const Auth = async function (tipo, datos) {
  return instance.post(tipo, datos);
};

export { Api, Auth };
