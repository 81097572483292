import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareRight,
  faPaperclip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCaretSquareDown } from "@fortawesome/free-solid-svg-icons";
import ReactTags from "react-tag-autocomplete";
import { observer } from "mobx-react";
import {
  message,
  Select,
  Switch,
  Form,
  Input,
  Space,
  Popconfirm,
  Menu,
  Dropdown,
  Button,
  Drawer,
  Popover,
} from "antd";

import { Api } from "../../../api/configApi";
import {
  setStoreActualizarLaEstructura,
  setStoreEtapas,
  store,
} from "../../../store";
import RenderAcciones from "./renderAcciones";
import Boton from "../../../components/design/elementos/boton";
import { PowerOffSharp } from "@material-ui/icons";

const { Option } = Select;

const CardEtapa = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [nombreydescripcion, setNombreydescripcion] = React.useState({
    nombre: "",
    descripcion: "",
  });

  const [etapaActiva, setEtapaActiva] = React.useState(false);
  const [lastags, setTags] = React.useState([]);
  const [placeholderOpciones, setPlaceholderOpciones] = React.useState();
  const [objetoAcciones, setObjetoAcciones] = React.useState([]);
  const [sugerencias, setSugerencias] = React.useState();
  const inputTags = React.useRef();
  const [parametroAbierto, setParametroabierto] = React.useState(false);
  const [opcionesAtributoActual, setOpcionesAtributoActual] = React.useState(
    []
  );
  const [modarActualizarGrupo, setModarActualizarGrupo] = React.useState(false);
  const [form] = Form.useForm();
  const [formGrupo] = Form.useForm();
  const [objetoEtapa, setObjetoEtapa] = React.useState({});
  const [consultaExpandida, setConsultaExpandida] = React.useState(false);

  const actualizarObjetoEtapa = (id, actualizar) => {
    const ElQuien = { ...form.getFieldsValue() };
    if (ElQuien.tipo === "lineaAscendente") {
      ElQuien.filtro.desde = +ElQuien.filtro.desde;
      ElQuien.filtro.hasta = +ElQuien.filtro.hasta;

      if (!ElQuien.filtro.desde) {
        ElQuien.filtro.desde = 1;
      }
      if (!ElQuien.filtro.hasta) {
        ElQuien.filtro.hasta = null;
      }
    }

    var o = { ...objetoEtapa };
    o._id = props.etapa._id;
    o.cuenta = store.cuentaactiva;
    o.consultas = lastags;
    o.acciones = objetoAcciones;
    o.activo = etapaActiva;
    o.nombre = nombreydescripcion.nombre;
    o.descripcion = nombreydescripcion.descripcion;
    o.flujo = props.etapa.flujo;
    o.prioridad = props.etapa.prioridad;
    o.grupo = props.etapa.grupo;
    o.quien = ElQuien;
    o.tagsConfig = {
      consultaExpandida: consultaExpandida,
      lastags: lastags,
      objetoAcciones: objetoAcciones,
      nombreydescripcion: nombreydescripcion,
      opcionesAtributoActual: opcionesAtributoActual,
      sugerencias: sugerencias,
      placeholderOpciones: placeholderOpciones,
    };

    setObjetoEtapa(o);
    if (actualizar) {
      Api("etapas", "Insert", o)
        .then((id) => {
          setLoading(false);
          message.success("Etapa Actualizada");
          actualizarObjetoEtapa(id, false);
          // props.GetEtapas();
        })
        .catch((err) => {
          message.error("No pudimos actualizar la etapa");
          setLoading(false);
          console.log(err);
        });
    }
  };
  const EliminarEtapa = async () => {
    try {
      const eliminar = await Api("etapas", "Delete", {
        etapa: props.etapa._id,
      });
      if (eliminar.success) {
        message.success("Etapa Eliminada");
        props.GetEtapas();
      }
    } catch (error) {
      message.error("Algo no salio bien");
    }
  };

  function OrdenarEtapas(prioridad, etapa) {
    const lasEtapas = [...store.etapas];
    // sacar el elemento que queremos mover
    const elemento = lasEtapas.find((elemento) => elemento._id === etapa._id);
    // sacar el elemento que queremos mover
    const index = lasEtapas.findIndex((elemento) => elemento._id === etapa._id);
    // eliminar el elemento que queremos mover
    lasEtapas.splice(index, 1);
    // insertar el elemento en la posicion nueva
    lasEtapas.splice(prioridad, 0, elemento);

    lasEtapas.forEach((elemento, index) => {
      elemento.prioridad = index;
    });

    setStoreEtapas(lasEtapas);

    // solo dejar los campos _id y prioridad
    const etapass = lasEtapas.map((e, index) => ({
      _id: e._id,
      prioridad: index,
    }));

    Api("etapas", "UpdatePrioridad", {
      etapas: etapass,
    })
      .then((res) => {
        message.success("Etapas Ordenadas");
      })
      .catch((err) => {
        message.error("No pudimos actualizar la etapa");
        setStoreActualizarLaEstructura(false);
        console.log(err);
      });
  }

  const guardar = () => {
    if (!form.getFieldsValue().tipo) return;
    setLoading(true);
    actualizarObjetoEtapa(false, true);
    setObjetoEtapa({ _id: props.etapa._id });
  };

  React.useEffect(() => {
    setNombreydescripcion({
      nombre: props.etapa.nombre,
      descripcion: props.etapa.descripcion,
    });
    setEtapaActiva(props.etapa.activo);
    if (props.etapa.tagsConfig.objetoAcciones.length > 0) {
      setObjetoAcciones(props.etapa.tagsConfig.objetoAcciones);
    }
    if (props.etapa.tagsConfig.lastags.length > 0) {
      setOpcionesAtributoActual(props.etapa.tagsConfig.opcionesAtributoActual);
      setSugerencias(props.etapa.tagsConfig.sugerencias);
      setPlaceholderOpciones(props.etapa.tagsConfig.placeholderOpciones);
      setTags(props.etapa.tagsConfig.lastags);
    }

    setConsultaExpandida(props.etapa?.tagsConfig?.consultaExpandida || false);
    form.setFieldsValue(props.etapa.quien);
  }, [props.etapa]);

  const actualizarSugerencias = (tipo, element, campo) => {
    const sugere = [];

    switch (tipo) {
      case "filtros":
        var placeholderopciones = "";
        store.losatributos.numbers
          .find((e) => e.nombre === element.campo)
          .filtros.forEach((filtro) => {
            var newid =
              "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
            sugere.push({
              id: newid,
              config: filtro,
              name: `${filtro.name} son`,
              tipo: "filtro",
            });
            placeholderopciones = placeholderopciones + " / " + filtro.name;
          });
        setSugerencias(sugere);
        setPlaceholderOpciones(placeholderopciones);
        break;
      case "atributos":
        store.losatributos.texts.forEach((atri) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Su ${atri.nombre} es`,
            tipo: "atributo text",
            opciones: atri.opciones,
            campo: atri.nombre,
          });
        });

        if (props.disparador?.variablesDisparador) {
          props.disparador.variablesDisparador?.forEach((vari) => {
            var newid =
              "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
            if (vari.tipoVariable === "campoListado") {
              vari.listado = props.disparador.listado;
              const opcionesTexto = ["multiple", "texto", "url", "imagen"];
              const opcionesNumero = ["numero"];
              const opcionesFecha = ["fecha"];
              const opcionesBoolean = ["switch"];

              const tip = store.listados
                .find((e) => e._id === vari.listado)
                ?.campos.find((e) => e.NombreCampo === vari.elcampo);
              if (tip?.opciones) {
                vari.opciones = tip.opciones;
              }

              opcionesTexto.includes(tip.tipoCampo) && (vari.tipo = "texto");
              opcionesNumero.includes(tip.tipoCampo) && (vari.tipo = "numero");
              opcionesFecha.includes(tip.tipoCampo) && (vari.tipo = "fecha");
              opcionesBoolean.includes(tip.tipoCampo) &&
                (vari.tipo = "booleano");
            }

            var lasOpciones = vari.opciones || [];
            lasOpciones = lasOpciones.map((e) => e.opcion);

            sugere.push({
              id: newid,
              name: `La variable ${vari.nombreVariable} es`,
              tipo: `variable ${vari.tipo}`,
              tipoVa: vari.tipoVariable,
              opciones: lasOpciones,
              valor: vari.nombreVariable,
              campo: vari.elcampo,
              listado: vari.listado,
            });
          });
        }

        if (lastags.length === 0) {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Su Red tiene`,
            tipo: "red",
          });
          sugere.push({
            id: newid,
            name: `Sin condicionales`,
            tipo: "sinCondicionales",
          });
        }
        var varDispa = [];
        if (props.disparador?.variablesDisparador) {
          varDispa = props.disparador?.variablesDisparador?.filter(
            (e) => e.tipoVariable === "atributoNumero"
          );
        }
        const atriNumbers = [...store.losatributos.numbers, ...varDispa];

        atriNumbers.forEach((atri) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Sus ${atri.nombre || atri.nombreVariable} son`,
            tipo: "atributo number",
            campo: atri.nombre || atri.nombreVariable,
          });
        });
        setSugerencias(sugere);
        break;

      case "text":
        var a = "";
        var s = [];
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;

        element.forEach((el) => {
          a = a + " / " + el;
          s.push({
            id: newid,
            name: el,
            tipo: "valor text",
            valor: el,
            class: "atributoNumero",
          });
        });
        s.push({
          id: newid,
          name: "INDEFINIDO",
          tipo: "valor text",
          valor: null,
          class: "atributoNumero",
        });
        a = a + " / INDEFINIDO";
        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    const ultimaTag = lastags[lastags.length - 1];

    setParametroabierto(true);
    if (
      ultimaTag === undefined ||
      ultimaTag.tipo === "and" ||
      ultimaTag.tipo === "que"
    ) {
      actualizarSugerencias("atributos");
      if (ultimaTag === undefined) {
        setPlaceholderOpciones("Su / Sus / La variable / Sin condicionales");
      } else {
        setPlaceholderOpciones("Su / Sus");
      }

      return;
    }

    const tagRf = ultimaTag.tipo;

    switch (tagRf) {
      case "variable numero":
      case "atributo number":
      case "filtro":
        setSugerencias([
          { id: 9888, name: "iguales a", tipo: "number", operador: "eq" },
          { id: 98882, name: "mayores a", tipo: "number", operador: "gt" },
          { id: 98833, name: "menores a", tipo: "number", operador: "lt" },
          {
            id: 988433,
            name: "iguales o menores a",
            tipo: "number",
            operador: "lte",
          },
          {
            id: 9838433,
            name: "iguales o mayores a",
            tipo: "number",
            operador: "gte",
          },
        ]);
        setPlaceholderOpciones(
          " iguales a  / mayores a  / menores a / iguales o mayores a / iguales o menores a"
        );
        break;
      case "valor text":
      case "valor number":
        setPlaceholderOpciones(" Y (Puedes agregar más condiciones)");
        setSugerencias([
          { id: 849834243, name: "Y", tipo: "and", class: "and" },
        ]);
        break;
      case "atributo number filtrado":
        actualizarSugerencias("filtros", ultimaTag);
        break;
      case "atributo text":
      case "variable texto":
        setSugerencias([
          { id: 554, name: "igual a", tipo: "text", operador: "eq" },
          { id: 5534, name: "diferente a", tipo: "text", operador: "ne" },
        ]);
        setPlaceholderOpciones(" igual a / diferente a");
        setOpcionesAtributoActual(ultimaTag.opciones);
        break;
      case "atributo list":
        setSugerencias();
        setPlaceholderOpciones("Numero de Dias / Atributo tipo Fecha");
        setParametroabierto(false);
        break;
      case "text":
        actualizarSugerencias("text", opcionesAtributoActual);
        break;
      case "sinCondicionales":
        setSugerencias([]);
        setPlaceholderOpciones("Fin de las condiciones");
        break;
      case "number":
        setPlaceholderOpciones("Numero a comparar");
        setParametroabierto(false);
        break;
      case "cantidad afiliados":
      case "valor nivelRed":
        setPlaceholderOpciones(" QUE (Agrega condicionales a estos afiliados)");
        setSugerencias([
          { id: 849834243, name: "QUE", tipo: "que", class: "que" },
        ]);
        break;
      case "cantidad afiliados nivel":
        setPlaceholderOpciones("numero del nivel separados por coma");
        setParametroabierto(false);
        break;
      case "number afiliados":
        setPlaceholderOpciones("Cantidad de afiliados");
        setParametroabierto(false);
        break;
      case "red":
        setPlaceholderOpciones(" Minimo / Máximo / Exactamente");
        setSugerencias([
          {
            id: 9844488,
            name: "minimo",
            tipo: "number afiliados",
            operador: "gte",
          },
          {
            id: 984124488,
            name: "máximo",
            tipo: "number afiliados",
            operador: "lte",
          },
          {
            id: 984124488,
            name: "exactamente",
            tipo: "number afiliados",
            operador: "eq",
          },
        ]);
        break;
      default:
        setParametroabierto(true);
        setPlaceholderOpciones("");
        break;
    }
  }, [lastags]);

  const onDelete = (i) => {
    const tags = lastags.slice(0);
    tags.splice(i, 1);
    setTags(tags);
  };

  const onAddition = (tag) => {
    tag = sugerencias.find((e) => e.name === tag.name);
    const tags = [...lastags, tag];
    setTags(tags);
  };

  const onInput = (value) => {
    if (parametroAbierto) {
      return;
    }

    const isnumber = parseFloat(value);

    if (isnumber >= 0) {
      if (placeholderOpciones === "Numero a comparar") {
        setSugerencias([
          {
            id: 32,
            tipo: "valor number",
            name: value,
            class: "atributoNumero",
            valor: Number(value),
          },
        ]);
      } else if (
        placeholderOpciones === "numero del nivel separados por coma"
      ) {
        var validar = value;
        var newValue = value;
        validar = validar.replaceAll(",", "");
        if (!Number(validar)) return;
        var newVale = newValue.split(",");
        newVale = newVale.map((e) => Number(e));
        setSugerencias([
          {
            id: 321,
            tipo: "valor nivelRed",
            name: value,
            class: "atributoNumero",
            valor: newVale,
          },
        ]);
      } else if (placeholderOpciones === "Cantidad de afiliados") {
        var plural = Number(value) === 1 ? "" : "s";
        const sugerenciasBase = [
          {
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "cantidad afiliados",
            name: `${Number(value)} afiliado${plural}`,
            class: "atributoNumero",
            valor: Number(value),
            filtro: "afiliados",
          },
        ];
        if (store.red.tipo === "unilevel") {
          plural = Number(value) === 1 ? "" : "s";
          sugerenciasBase.push({
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "cantidad afiliados nivel",
            name: `${Number(value)} afiliado${plural} en el nivel`,
            class: "atributoNumero",
            valor: Number(value),
            filtro: "afiliadosNivel",
          });
        }
        setSugerencias(sugerenciasBase);
      } else {
        setSugerencias([
          {
            id: "valorid" + Math.floor(Math.random() * (100000 - 1000)) + 1000,
            tipo: "valor number",
            name: `hace ${Number(value)} Dia(s) son`,
            class: "atributoNumero",
            valor: Number(value),
          },
        ]);
      }
    }
  };

  const NuevaAccion = () => {
    const newId = "accion" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
    const nuevoObjeto = [...objetoAcciones];
    nuevoObjeto.push({ id: newId });
    setObjetoAcciones(nuevoObjeto);
  };

  const EliminarAccion = (id) => {
    const limparAccion = objetoAcciones.filter((e) => e.id !== id);
    setObjetoAcciones(limparAccion);
  };

  const OrdenDeEtapas = (
    <Menu>
      {store.etapas.map((e, i) => {
        return (
          e._id !== props.etapa._id && (
            <Menu.Item
              onClick={() => {
                OrdenarEtapas(i, props.etapa);
              }}
              key={e._id}
            >
              {i + 1}
            </Menu.Item>
          )
        );
      })}
    </Menu>
  );

  function TagComponent({ tag, removeButtonText, onDelete }) {
    var estilecho = "";
    switch (tag.tipo) {
      case "valor text":
      case "valor number":
      case "valor nivelRed":
        estilecho = "tagsValorText";
        break;
      case "and":
        estilecho = "tagsY";
        break;
      case "sinCondicionales":
        estilecho = "tagsSinCondicionales";
        break;
      default:
        estilecho = "tagsDefault";
        break;
    }
    return (
      <span
        className={estilecho}
        style={{ marginRight: 5, marginBottom: 8, padding: 10 }}
      >
        {tag.name}
      </span>
    );
  }
  function expandirEtapa() {
    if (!consultaExpandida) {
      setConsultaExpandida(true);
    }
  }
  function actualizarGrupo(values) {
    Api("etapas", "UpdateGrupo", {
      etapa: { _id: props.etapa._id, grupo: values.grupo },
    })
      .then((res) => {
        message.success("Grupo Actualizado");
        setModarActualizarGrupo(false);
        props.GetEtapas();
      })
      .catch((err) => {
        message.error("No pudimos actualizar el grupo");
        setStoreActualizarLaEstructura(false);
        console.log(err);
      });
  }
  return (
    <>
      <Drawer
        visible={modarActualizarGrupo}
        title="Cambiar de grupo"
        closable
        onClose={() => {
          setModarActualizarGrupo(false);
          formGrupo.resetFields();
        }}
        destroyOnClose
      >
        <Space direction="vertical">
          <Form
            form={formGrupo}
            onFinish={actualizarGrupo}
            initialValues={{ grupo: props.etapa.grupoEtapa }}
          >
            <Form.Item name="grupo">
              <Select style={{ width: 300 }}>
                <Select.Option value="sinGrupo">Sin grupo</Select.Option>
                {props.grupos.map((e, i) => {
                  return (
                    <Select.Option value={e.id} key={e.id}>
                      {e.nombre}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Button htmlType="submit">Guardar Etapa</Button>
          </Form>
        </Space>
      </Drawer>
      <div
        className={!consultaExpandida ? "hoverCaja" : "caja"}
        style={{ cursor: !consultaExpandida ? "pointer" : "" }}
        onClick={expandirEtapa}
      >
        <FontAwesomeIcon
          className="iconoExpandirConsultas"
          onClick={() => {
            setConsultaExpandida(!consultaExpandida);
          }}
          icon={consultaExpandida ? faCaretSquareDown : faCaretSquareRight}
        />

        <input
          style={{ minWidth: nombreydescripcion.nombre.length * 13 }}
          type="text"
          placeholder="Nombre del flujo..."
          value={nombreydescripcion.nombre}
          onChange={(e) => {
            setNombreydescripcion({
              nombre: e.target.value,
              descripcion: nombreydescripcion.descripcion,
            });
          }}
          className="titular"
        />
        <input
          style={{
            fontSize: 14,
            fontWeight: 300,
            minWidth: nombreydescripcion.descripcion.length * 8,
          }}
          type="text"
          placeholder="Descripción del flujo..."
          value={nombreydescripcion.descripcion}
          onChange={(e) => {
            setNombreydescripcion({
              nombre: nombreydescripcion.nombre,
              descripcion: e.target.value,
            });
          }}
          className="subtitular"
        />

        <div className="inputbotonguardar">
          <Space size="middle">
            {consultaExpandida && (
              <Boton
                loading={loading}
                onClick={() => {
                  guardar();
                }}
                tipo="success"
                texto="Guardar"
              />
            )}

            <Switch
              style={{ marginLeft: 10 }}
              color="primary"
              checked={etapaActiva}
              onChange={(e) => {
                setEtapaActiva(!etapaActiva);
              }}
            />
            <Dropdown trigger="click" overlay={OrdenDeEtapas}>
              <div className="posicionEtapa">
                <span>
                  {store.etapas
                    .map(function (e) {
                      return e._id;
                    })
                    .indexOf(props.etapa._id) + 1}
                </span>
              </div>
            </Dropdown>

            <Popover
              content={
                props.grupos.find((e) => e.id === props.etapa.grupoEtapa)
                  ?.nombre || "Sin grupo"
              }
            >
              <FontAwesomeIcon
                style={{
                  cursor: "pointer",
                  color: "rgb(111 111 111)",
                  fontSize: 18,
                }}
                icon={faPaperclip}
                onClick={() => setModarActualizarGrupo(true)}
              />
            </Popover>

            {/* {props.disparador.tipo === "cron" && (
              <PlayPulpitos etapa={props.etapa} />
            )} */}
            <Popconfirm
              title="¿Deseas Eliminar La Etapa?"
              okText="Si, ELIMINAR"
              cancelText="Cancelar"
              onConfirm={EliminarEtapa}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer", color: "rgb(111 111 111)" }}
                icon={faTrash}
              />
            </Popconfirm>
          </Space>
        </div>

        {consultaExpandida && (
          <div style={{ position: "relative" }}>
            <Form form={form} layout="vertical">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Item
                  label="Quien:"
                  style={{
                    marginRight: 10,
                    minWidth: 300,
                    marginTop: 10,
                    marginBottom: 0,
                  }}
                  name="tipo"
                  initialValue={props.etapa.quien}
                >
                  <Select>
                    <Option value="todos">Todos</Option>
                    {props.disparador.tipo === "compra" && (
                      <>
                        <Option value="afiliadoComprador">
                          Afiliado Comprador
                        </Option>
                        <Option value="lineaAscendente">
                          Linea Ascendente
                        </Option>
                        <Option value="patrocinador">Patrocinador</Option>
                      </>
                    )}
                    {props.disparador.tipo === "registro" && (
                      <>
                        <Option value="afiliadoRegistrado">
                          Afiliado Registrado
                        </Option>
                        <Option value="patrocinador">Patrocinador</Option>
                      </>
                    )}
                    {props.disparador.tipo === "listados" && (
                      <>
                        <Option value="afiliadoItemListados">
                          Afiliado del item
                        </Option>
                        <Option value="lineaAscendente">
                          Linea Ascendente
                        </Option>
                        <Option value="patrocinador">Patrocinador</Option>
                      </>
                    )}

                    <Option value="banderaRoja">Con bandera Roja</Option>
                    <Option value="banderaAzul">Con bandera Azul</Option>
                    <Option value="banderaNegra">Con bandera Negra</Option>
                    <Option value="banderaVerde">Con bandera Verde</Option>
                    <Option value="banderaAmarilla">
                      Con bandera Amarilla
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldsValue }) => {
                    return (
                      getFieldsValue().tipo === "lineaAscendente" && (
                        <>
                          <Form.Item name="filtro" noStyle></Form.Item>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 0,
                            }}
                          >
                            <Form.Item
                              style={{
                                display: "flex",
                                marginRight: 10,
                                marginBottom: 0,
                              }}
                              label="Desde el nivel"
                              name={["filtro", "desde"]}
                            >
                              <Input type="number" />
                            </Form.Item>
                            <Form.Item
                              style={{ display: "flex", marginBottom: 0 }}
                              label="Hasta el nivel"
                              name={["filtro", "hasta"]}
                            >
                              <Input type="number" />
                            </Form.Item>
                          </div>
                        </>
                      )
                    );
                  }}
                </Form.Item>
              </div>
            </Form>
            <div style={{ marginTop: 20 }}></div>
            <ReactTags
              minQueryLength={1}
              allowUnique={false}
              onInput={onInput}
              ref={inputTags}
              tags={lastags}
              suggestions={sugerencias}
              onDelete={onDelete.bind(this)}
              onAddition={onAddition.bind(this)}
              placeholderText={placeholderOpciones}
              autoresize={false}
              tagComponent={TagComponent}
            />
            <div className="inputAcciones">
              <RenderAcciones
                objetoAcciones={objetoAcciones}
                setObjetoAcciones={setObjetoAcciones}
                EliminarAccion={EliminarAccion}
                disparador={props.disparador.tipo}
                objetoDisparador={props.disparador}
              />
            </div>
            <Button
              onClick={() => {
                NuevaAccion();
              }}
              style={{ color: "#000", marginTop: 10 }}
            >
              Agregar Acción
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(CardEtapa);
